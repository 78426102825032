export const errorCodeOtp = {
	'-1': 'Sending error (Message content unicode character)',
	'12': 'Template block',
	'-2': 'Wrong user or password or IP',
	'-3': 'System error',
	'-4': 'Template Wrong',
	'-5': 'Lôi hệ thống',
	'0': 'Success',
	'100': 'Authentication fail',
	'101': 'Authentication User is deactived',
	'102': 'Authentication User is expired',
	'103': 'Authentication User is locked',
	'104': 'Template not actived',
	'105': 'Template does not exist',
	'108': 'Msisdn in blackList',
	'2': 'Not enough account',
	'3': 'Reciever is null',
	'304': 'Send the same content in short time',
	'4': 'Invalid reciever',
	'400': 'Not enough money',
	'5': 'Target is null',
	'6': 'Error:[error detail]',
	'7': 'Hết số lượng tin nhắn gửi test',
	'900': 'System is error',
	'901':
		'Lenght of messages is 612 with noneUnicode message and 266 with Unicode message',
	'902': 'Number of msisdn must be >0',
	'904': 'Inactive',
	'99': 'Error in processing : [error detail]',
}
