/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { Flex, Stack } from '@mantine/core'
import { useLocation } from 'react-router-dom'
import { getListSetting } from 'src/apis/settingAPI'
import { useLoading } from 'src/context/LoadingContext'
import { useModal } from 'src/context/ModalContext'
import { SettingData } from 'src/interfaces'

function TermsAndPolicy() {
	const { openLoadingContext, closeLoadingContext } = useLoading()
	const { openError } = useModal()
	const { pathname } = useLocation()
	const field = pathname.replace('/', '')
	const [data, setData] = useState<SettingData>()

	const handleGetData = useCallback(async () => {
		let arr: SettingData = null
		openLoadingContext()
		try {
			const res = await getListSetting({
				fields: ['$all'],
				page: 1,
				limit: 10,
				where: { field: field.toUpperCase() },
			})
			arr = res?.data?.results?.objects?.rows?.[0]
		} catch (error) {
			openError(error)
		} finally {
			setData(arr)

			setTimeout(() => {
				closeLoadingContext()
			}, 500)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	useEffect(() => {
		handleGetData()
	}, [])
	return (
		<Stack sx={{ padding: 10 }}>
			<Flex align="center" justify="center" gap={8} sx={{ flexWrap: 'wrap' }}>
				{field?.toUpperCase()}
			</Flex>
			<div
				dangerouslySetInnerHTML={{
					__html: data?.value,
				}}
			></div>
		</Stack>
	)
}

export default React.memo(TermsAndPolicy)
