import { convertParams } from 'src/utils/utils'
import axios from '../axios'
import { SETTINGROUTES } from 'src/router/Api'
export const getListSetting = async (params) => {
	return await axios.get(SETTINGROUTES.name, {
		params: convertParams(params),
	})
}
export const getOneSetting = async (id, params) => {
	return await axios.get(`${SETTINGROUTES.name}/${id}`, {
		params: convertParams(params),
	})
}

export const updateSetting = async (id: string, body: any) => {
	return await axios.put(`${SETTINGROUTES.name}/${id}`, body)
}
