export const service = {
	APP: 'App',
	WEB: 'Web',
}
export const serviceTypes = [
	{
		label: service.APP,
		value: 'APP',
	},
	{
		label: service.WEB,
		value: 'WEB',
	},
]
