import dayjs from 'dayjs'
import {
	formatDate,
	formatDateTime,
	timestampFields,
} from '../variable/constant'

export const generateRandomNumber = (min = 0, max = 0) => {
	return (
		Math.floor(Math.random() * ((max || 2000) - (min || 100) + 1)) +
		(min || 100)
	)
}
export const createRandomArray = (n: any) => {
	const randomArray = []
	for (let i = 0; i < n; i++) {
		const randomNumber = generateRandomNumber()
		randomArray.push(randomNumber)
	}
	return randomArray
}

export const convertParams = (params: { [key: string]: any }) => {
	return Object.fromEntries(
		Object.entries(params).map(([key, value]) => [key, JSON.stringify(value)]),
	)
}

export const convertPhoneNumber = (phoneNumber) => {
	const prefixes = ['+84']
	const results = [] as any

	if (phoneNumber.startsWith('0')) {
		results.push(phoneNumber)
		prefixes.forEach((prefix) => {
			const convertedNumber = prefix + phoneNumber.slice(1)
			results.push(convertedNumber)
		})
	} else {
		results.push(phoneNumber)
	}

	return results
}
export const convertDate = (value: string, fields: string) => {
	if (value) {
		const format = timestampFields.includes(fields)
			? formatDate
			: formatDateTime
		const dateValue = isNaN(Number(value)) ? value : Number(value)
		return dayjs(dateValue).format(format)
	} else {
		return ''
	}
}
export const formatTime = (seconds: number) => {
	const hours = Math.floor(seconds / 3600)
	const minutes = Math.floor((seconds % 3600) / 60)
	const secs = seconds % 60

	const formattedTime = [
		hours > 0 ? `${hours}h` : '',
		minutes > 0 ? `${minutes}m` : '',
		secs > 0 ? `${secs}s` : '',
	]
		.filter(Boolean)
		.join('')

	return formattedTime || '0s' // Trả về "0s" nếu tất cả đều là 0
}

export const adjustToNearestMinutes = (
	sending_unix_timestamp,
	minutes: number,
) => {
	const currentMinute = dayjs(sending_unix_timestamp).minute()

	// Làm tròn lên đến bội số của 5
	const roundedMinute = Math.ceil(currentMinute / minutes) * minutes

	return dayjs(sending_unix_timestamp)
		.startOf('minute') // Đảm bảo chỉ lấy phần phút
		.minute(roundedMinute) // Cập nhật phút đã làm tròn
		.second(0) // Đặt giây về 0
		.valueOf() // Trả về timestamp
}

export const addMinutesAndRound = (date, minutesToAdd, minutesRound) => {
	// Thêm số phút vào thời gian hiện tại
	const newDate = dayjs(date).add(minutesToAdd, 'minutes')

	// Lấy phút hiện tại và làm tròn lên đến bội số của minutesRound
	const roundedMinutes =
		Math.ceil(newDate.minute() / minutesRound) * minutesRound

	// Đặt phút đã làm tròn, giây và mili giây về 0
	return newDate.minute(roundedMinutes).second(0).millisecond(0).toDate()
}

export const isUrl = (url: string) => {
	// eslint-disable-next-line no-useless-escape
	const urlRegex = /^(https?:\/\/)[\w.-]+\.[a-z]{2,}([\/?#][^\s]*)?$/i

	// Ví dụ sử dụng
	return urlRegex.test(url)
}
