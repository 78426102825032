import { Title, Text, Container, Grid, Image, Button } from '@mantine/core'

const SectionTwo = () => {
	//const theme = useMantineTheme();

	return (
		<section id="section-two">
			<Container>
				<Grid justify="space-around">
					<Grid.Col xs={6} sm={8} md={8} lg={8}>
						<div style={{ marginBottom: 20 }}>
							<Text color="black">
								<Title order={1}>Hangouts & Events</Title>
								Meet New Friends Practice languages, and join local gatherings
							</Text>
						</div>
						<Button color="green">Check it out</Button>
					</Grid.Col>
					<Grid.Col xs={6} sm={4} md={4} lg={4}>
						<Image
							src={require('src/images/hangout.jpg')}
							alt={'sample1'}
							style={{ width: '100%' }}
						/>
					</Grid.Col>
				</Grid>
			</Container>
		</section>
	)
}

export default SectionTwo
