import React, { useCallback } from 'react'
import { Stack, Typography } from '@mui/material'
import styles from './ModalChangePassword.module.scss'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import InputFormPassword from 'src/components/Form/InputFormPassword'
import CustomModalClose from 'src/components/CustomComponents/CustomModalClose'
import * as authAPI from 'src/apis/authAPI'
import md5 from 'md5'
import CusTomButton from 'src/components/CustomComponents/CustomButton'
import { useLoading } from 'src/context/LoadingContext'
import { useModal } from 'src/context/ModalContext'
import { passwordRules } from 'src/variable/constant'

const SignupSchema = yup.object().shape({
	old_password: yup.string().required('Old password is a required field'),
	new_password: yup
		.string()
		.required('New password is a required field')
		.matches(passwordRules, {
			message:
				'Password must be 8-20 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character',
		}),
	confirm_new_pass: yup
		.string()
		.oneOf([yup.ref('new_password'), null], 'Passwords must match')
		.required('Confirm new password is a required field'),
})

function ModalChangePassword({ open, handleClose }) {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(SignupSchema),
		mode: 'onChange', // Validate on change
		reValidateMode: 'onChange', // Re-validate on blur
	})
	const { openLoadingContext, closeLoadingContext } = useLoading()
	const { openModal, closeModal, openError } = useModal()
	const handleChangePass = useCallback(
		async (body) => {
			openLoadingContext()
			try {
				const res = await authAPI.changePassWord({
					old_password: md5(body.old_password),
					new_password: md5(body.new_password),
				})
				const { data } = res
				if (data?.code === 200) {
					openModal({
						type: 'success',
						label: 'You have successfully changed your password !',
					})
					handleClose()
				}
			} catch (error) {
				closeModal()
				openError(error)
			} finally {
				closeLoadingContext()
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			openLoadingContext,
			closeLoadingContext,
			openModal,
			handleClose,
			closeModal,
		],
	)
	const onSubmit = (data) => {
		openModal({
			type: 'confirm',
			label: <div>Do you want to change password?</div>,
			handleConfirm: () => {
				handleChangePass(data)
			},
		})
	}

	return (
		<CustomModalClose
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<>
				<Typography
					id="modal-modal-title"
					variant="h6"
					component="h2"
					className={styles.title}
				>
					Change Password
				</Typography>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Stack spacing={2} className={styles.formChangePass}>
						<div>
							<InputFormPassword
								name="old_password"
								register={register}
								errors={errors}
								label="Old password"
								placeholder="Enter your old password"
							/>
						</div>
						<div>
							<InputFormPassword
								name="new_password"
								register={register}
								errors={errors}
								label="New password"
								placeholder="Enter your new password"
							/>
						</div>
						<div>
							<InputFormPassword
								name="confirm_new_pass"
								register={register}
								errors={errors}
								label="Confirm password"
								placeholder="Confirm new password"
							/>
						</div>
						<CusTomButton type="submit" variant="outlined">
							Submit
						</CusTomButton>
					</Stack>
				</form>
			</>
		</CustomModalClose>
	)
}

export default React.memo(ModalChangePassword)
