import React from 'react'
import { Stack } from '@mui/material'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar'
import nav from 'src/router/nav'
import styles from './SideBar.module.scss'
import { Link, useLocation } from 'react-router-dom'
import { MdOutlineCloseFullscreen } from 'react-icons/md'
import cmsImage from 'src/images/cms.png'
function SideBar({ show, setShow }) {
	const location = useLocation()
	const pathname = location.pathname?.split('/').splice(0, 2).join('/')

	return (
		<Stack className={`${styles.wrapper} ${show ? styles.show : styles.hide}`}>
			<Stack
				className={`${styles.container} ${show ? styles.show : styles.hide}`}
			>
				<img src={cmsImage} alt="logo" />
				<Sidebar className={styles.sidebar}>
					<Menu className={styles.menu}>
						{nav.map((item) =>
							item?.children?.length > 0 ? (
								<SubMenu
									className={styles.SubMenu}
									defaultOpen={
										item?.children?.map((child) => child.to).includes(pathname)
											? true
											: false
									}
									label={item?.name}
									key={item?.name}
									icon={item?.icon}
								>
									{item?.children?.map((child) => {
										return (
											<MenuItem
												key={child?.name}
												icon={child?.icon}
												className={`${styles.Dropdown} ${
													pathname === child.to ? styles.active : ''
												} `}
												component={<Link to={child.to} />}
											>
												{child?.name}
											</MenuItem>
										)
									})}
								</SubMenu>
							) : (
								<div key={item?.name}>
									<MenuItem
										icon={item?.icon}
										className={`${styles.MenuItem} ${
											pathname === item?.to ? styles.active : ''
										} `}
										component={<Link to={item?.to} />}
									>
										{item?.name}
									</MenuItem>
								</div>
							),
						)}
						<br /> <br /> <br />
					</Menu>
				</Sidebar>
				<MdOutlineCloseFullscreen
					onClick={() => setShow((pre) => !pre)}
					className={styles.closeSidebar}
				></MdOutlineCloseFullscreen>
			</Stack>
		</Stack>
	)
}

export default React.memo(SideBar)
