import React, { ReactNode } from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import { useLoading } from 'src/context/LoadingContext'
import { color } from 'src/variable/constant'

interface CustomButtonProps extends ButtonProps {
	children: ReactNode
	[key: string]: any
}
function CustomButton({ children, sx, ...props }: CustomButtonProps) {
	const { loadingContext } = useLoading()
	return (
		<Button
			disabled={loadingContext}
			sx={{
				borderColor: color.CmsPrimary,
				color: color.CmsPrimary,
				':hover': {
					color: 'white',
					background: `${color.CmsPrimary} !important`,
				},
				...sx,
			}}
			{...props}
		>
			{children}
		</Button>
	)
}

export default React.memo(CustomButton)
