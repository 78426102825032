export const frequency = {
	ONE_TIME: 'One time',
	REGULAR_EVERY_7_DAYS: 'Regular every 7 days',
	REGULAR_EVERY_30_DAYS: 'Regular every 30 days',
}
export const frequencyOpts = [
	{
		label: frequency.ONE_TIME,
		value: 'ONE_TIME',
	},
	{
		label: frequency.REGULAR_EVERY_7_DAYS,
		value: 'REGULAR_EVERY_7_DAYS',
	},
	{
		label: frequency.REGULAR_EVERY_30_DAYS,
		value: 'REGULAR_EVERY_30_DAYS',
	},
]
