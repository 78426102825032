import { Grid, Text, Container, Title, Image, Button } from '@mantine/core'

const SectionThree = () => {
	//const theme = useMantineTheme();

	return (
		<section id="section-three">
			<Container>
				<Grid justify="space-around">
					<Grid.Col xs={6} sm={4} md={4} lg={4}>
						<Image
							src={require('src/images/network.jpg')}
							alt={'sample2'}
							style={{ width: '100%' }}
						/>
					</Grid.Col>
					<Grid.Col xs={6} sm={8} md={8} lg={8}>
						<div style={{ marginBottom: 20 }}>
							<Text color="black">
								<Title order={1}>Networks</Title>
								Connect & Collaborate Join Communities and Grow Your Business
							</Text>
						</div>
						<Button color="green">Tell me more</Button>
					</Grid.Col>
				</Grid>
			</Container>
		</section>
	)
}

export default SectionThree
