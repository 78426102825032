export const AUTHROUTES = {
	login: '/auth/employee_login',
	logout: '/auth/logout',
	change_password: '/employee/change_password',
	dynamicLink: '/system-settings/get-link',
}
export const USERROUTES = {
	name: 'user',
}
export const SETTINGROUTES = {
	name: 'system-settings',
}
export const CATEGORYTRAILS = {
	name: 'category',
	trail: 'trail',
	addTrails: 'category_trail',
	sort: 'category/sort',
}
export const UPLOADROUTES = {
	name: '/image/upload',
}
export const CHARTROUTES = {
	name: '/statistic/users',
	information: '/statistic/information',
	provinceUser: 'statistic/get-chart-province-user',
}
export const CONFIGPHONEROUTES = {
	name: 'phone-special',
}
export const OTPROUTES = {
	optTracking: 'otp-tracking',
	balance: 'otp-tracking/balance',
}
export const NOTIROUTES = {
	name: 'push_notification',
	pushNoti: 'push_notification/send_fcm_to_group',
}
export const SERVICEROUTES = {
	name: 'addition',
}
export const CONVERSIONROUTES = {
	name: 'conversation',
}
