import { convertParams } from 'src/utils/utils'
import axios from '../axios'
import { NOTIROUTES } from 'src/router/Api'
export const getListNoti = async (params) => {
	return await axios.get(NOTIROUTES.name, {
		params: convertParams(params),
	})
}
export const getOneNoti = async (id, params) => {
	return await axios.get(`${NOTIROUTES.name}/${id}`, {
		params: convertParams(params),
	})
}
export const createNoti = async (payload) => {
	return await axios.post(NOTIROUTES.name, payload)
}
export const editNoti = async (id, payload) => {
	return await axios.put(`${NOTIROUTES.name}/${id}`, payload)
}
export const deleteNoti = async (id) => {
	return await axios.delete(`${NOTIROUTES.name}/${id}`)
}
export const pushNoti = async (id) => {
	return await axios.post(`${NOTIROUTES.pushNoti}/${id}`)
}
