import React, { useCallback, useEffect } from 'react'
import { useForm } from '@mantine/form'
import { formatDate, passwordRules } from 'src/variable/constant'
import { Flex, Stack, TextInput } from '@mantine/core'
import CustomPasswordInput from 'src/components/CustomComponents/CustomInputPassword/CustomPasswordInput'
import CustomButton from 'src/components/CustomComponents/CustomButton'
import md5 from 'md5'
import dayjs from 'dayjs'
import { createGuider } from 'src/apis/userAPI'
import CustomDatePickerInput from 'src/components/CustomComponents/CustomDatePickerInput'
import { useModal } from 'src/context/ModalContext'
import CustomModal from 'src/components/CustomComponents/CustomModal'

interface ModalCreateGuiderProps {
	open: boolean
	onClose: () => void
	handleGetData: () => void
	pagination: {
		pageIndex: number
		pageSize: number
	}
	handleChangePage: (_value: number) => void
	[key: string]: any
}

function ModalCreateGuider({
	open,
	onClose,
	handleGetData,
	pagination,
	handleChangePage,
}: ModalCreateGuiderProps) {
	const form = useForm({
		initialValues: {
			name: '',
			email: '',
			phone: '',
			password: '',
			confirmpassword: '',
			birthday: null,
		},

		validate: {
			name: (value) => {
				if (!value.trim()) return 'Name cannot be empty'
				if (value.trim().length < 2 || value.length > 50)
					return 'Name must be 2-50 characters long'
				return null
			},
			email: (value) => {
				if (value) {
					const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
					if (regex.test(value)) return null
					else return 'Invalid Email'
				}
				return null
			},
			password: (value) => {
				if (!value.trim()) return 'Password cannot be empty'
				if (!passwordRules.test(value)) {
					return 'Password must be 8-20 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character'
				}
			},
			confirmpassword: (value) => {
				if (!value.trim()) return 'Confirm password cannot be empty'
				if (value !== form.values.password) return 'Passwords did not match'
			},
			phone: (value) => {
				const phoneRegex = /^\+?\d+$/
				if (!value) return 'Phone cannot be empty'
				if (!phoneRegex.test(value)) return 'Invalid phone'
				if (value.length < 10 || value.length > 15)
					return 'Phone must be 10-15 characters long'
				return null
			},
		},
		validateInputOnBlur: true,
		validateInputOnChange: true,
	})
	const { openModal, closeModal, openError } = useModal()
	const handleCreateGuider = useCallback(async (body) => {
		try {
			const res = await createGuider(body)
			if (res?.data?.code === 200) {
				if (pagination.pageIndex === 1) {
					await handleGetData()
				} else {
					await handleChangePage(1)
				}
				openModal({
					type: 'success',
					label: 'Guider has been created successfully ',
					handleClose: () => {
						closeModal()
						onClose()
					},
				})
			}
		} catch (error) {
			console.log(
				'🧑‍🏭 TrieuNinhHan ~ file: ModalCreateGuider.tsx:68 ~ handleSubmit ~ error:',
				error,
			)
			openError(error)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const handleSubmit = useCallback(async (values) => {
		const body = {
			...values,
			password: md5(values.password),
			birthday: values.birthday
				? dayjs(values.birthday).valueOf().toString()
				: '',
			phone: String(values?.phone)?.startsWith('0')
				? '+84' + values?.phone.slice(1)
				: values?.phone,
		}

		delete body.confirmpassword
		openModal({
			type: 'confirm',
			label: 'Do you want to create guider ?',
			handleClose: () => closeModal(),
			handleConfirm: () => {
				handleCreateGuider(body)
			},
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	useEffect(() => {}, [])
	return (
		<CustomModal
			// size="auto"

			opened={open}
			onClose={onClose}
		>
			<form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
				<Stack spacing="0.75rem">
					<Flex sx={{ fontSize: 25, fontWeight: 700 }} justify="center">
						Create guider
					</Flex>
					<TextInput
						autoComplete="off"
						withAsterisk
						key="name"
						label={'Name'}
						name={'name'}
						placeholder={"Guider's name"}
						maxLength={50}
						{...form.getInputProps('name')}
					/>
					<TextInput
						autoComplete="new-password"
						withAsterisk
						key="phone"
						label={'Phone'}
						name={'phone'}
						placeholder={"Guider's Phone"}
						maxLength={50}
						{...form.getInputProps('phone')}
					/>

					<CustomPasswordInput
						autoComplete="new-password"
						withAsterisk
						key="password"
						label={'Password'}
						name={'password'}
						placeholder={"Guider's password"}
						maxLength={50}
						{...form.getInputProps('password')}
					/>
					<CustomPasswordInput
						autoComplete="new-password"
						withAsterisk
						key="confirmpassword"
						label={'Confirm password'}
						name={'confirmpassword'}
						placeholder={"Guider's confirm password"}
						maxLength={50}
						{...form.getInputProps('confirmpassword')}
					/>
					<TextInput
						autoComplete="off"
						key="email"
						label={'Email'}
						name={'email'}
						placeholder={"Guider's email"}
						maxLength={50}
						{...form.getInputProps('email')}
					/>
					<CustomDatePickerInput
						key={'birthday'}
						label={'Birthday'}
						name={'birthday'}
						valueFormat={formatDate}
						defaultValue={null}
						maxDate={new Date()}
						{...form.getInputProps('birthday')}
					/>
					<CustomButton variant="outlined" type="submit">
						Create
					</CustomButton>
				</Stack>
			</form>
		</CustomModal>
	)
}

export default React.memo(ModalCreateGuider)
