import React, { useCallback, useEffect, useState } from 'react'
import { Flex, Stack } from '@mantine/core'
import dayjs from 'dayjs'
import { MRT_ColumnDef, MRT_PaginationState } from 'mantine-react-table'
import TableComponent from 'src/components/TableComponent'
import { OtpTrackingData } from 'src/interfaces'
import { color, formatDateTime } from 'src/variable/constant'
import { useModal } from 'src/context/ModalContext'
import { getListOtpTracking, getListOtpTrackingBalance } from 'src/apis/optAPI'
import CustomSelect from 'src/components/CustomComponents/CustomSelect'
import CustomClearInput from 'src/components/CustomComponents/CustomClearInput'
import { filterNormalIlike } from 'src/utils/filter'
import { errorCodeOtp } from 'src/variable/code'

interface OtpTrackingPageListProps {
	columns: MRT_ColumnDef[]
	fields: {
		value: string
		label: string
	}[]
	[key: string]: any
}

function OtpTrackingPageList({ columns, fields }: OtpTrackingPageListProps) {
	const [data, setData] = useState<OtpTrackingData[]>([])
	const [pagination, setPagination] = useState<MRT_PaginationState>({
		pageIndex: 1,
		pageSize: 10,
	})
	const [value, setValue] = useState({
		field: fields[0].value,
		value: null,
	})
	const [total, setTotal] = useState<number>(0)
	const [balance, setBalance] = useState<number>(0)
	const [loading, setLoading] = useState<boolean>(false)
	const { openError } = useModal()
	const handleGetData = useCallback(async () => {
		let arr: OtpTrackingData[] = []
		let count: number = 0
		let filter = {}
		filter = filterNormalIlike(value)
		setLoading(true)
		try {
			const res = await getListOtpTracking({
				fields: ['$all'],
				page: pagination.pageIndex,
				limit: pagination.pageSize,
				where: { ...filter },
			})
			arr = res?.data?.results?.objects?.rows?.map((item, index) => ({
				no: index + 1 + pagination.pageSize * (pagination.pageIndex - 1),
				...item,
				gateway: item.result.detail,
				status: errorCodeOtp[String(item.result.result)] || errorCodeOtp[0],
				created_at: dayjs(item?.created_at).format(formatDateTime),
			}))
			count = res?.data?.results?.objects?.count
		} catch (error) {
			openError(error)
		} finally {
			setData(arr)
			setTotal(count)
			setTimeout(() => {
				setLoading(false)
			}, 500)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(pagination), JSON.stringify(value)])
	const handleGetBalance = useCallback(async () => {
		let _balance = 0
		try {
			const resBalance = await getListOtpTrackingBalance({})
			_balance = resBalance?.data?.results?.object?.balance
		} catch (error) {
		} finally {
			setBalance(_balance)
		}
	}, [])
	const handleChangePage = useCallback((value: number) => {
		setPagination((pre) => ({
			...pre,
			pageIndex: value,
		}))
	}, [])
	const handleSetValue = useCallback(
		(e, key) => {
			if (key === 'field' && value.value === '') {
				setValue((pre) => ({ ...pre, [key]: e, value: null }))
			} else {
				setValue((pre) => ({ ...pre, [key]: e }))
			}
		},
		[value.value],
	)
	useEffect(() => {
		let timer
		if (value.value !== null) {
			timer = setTimeout(() => {
				if (pagination.pageIndex === 1) {
					handleGetData()
				} else handleChangePage(1)
			}, 500)
		}
		return () => clearTimeout(timer)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(value)])
	useEffect(() => {
		handleGetData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(pagination)])
	useEffect(() => {
		handleGetBalance()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Stack sx={{ width: '100%' }}>
			<Flex
				gap={8}
				justify="space-between"
				sx={{ flexWrap: 'wrap' }}
				align="center"
			>
				<Flex gap={8} sx={{ flexWrap: 'wrap' }}>
					<CustomSelect
						value={value.field}
						data={fields}
						onChange={(e) => {
							handleSetValue(e, 'field')
						}}
					/>
					<CustomClearInput
						value={value.value}
						onChange={(e) => handleSetValue(e, 'value')}
						placeholder={'Search'}
					/>
				</Flex>
				<Flex
					justify="flex-end"
					sx={{ fontSize: 20, fontWeight: 600, color: color.CmsPrimary }}
				>
					<div>Balance: {Number(balance).toLocaleString('en-US') || ''}</div>
				</Flex>
			</Flex>
			<TableComponent
				columns={columns}
				total={total}
				data={data}
				pagination={pagination}
				loading={loading}
				handleChangePage={handleChangePage}
				enableRowActions={false}
			/>
		</Stack>
	)
}

export default React.memo(OtpTrackingPageList)
