import React from 'react'
import ChartDonut from 'src/components/Chart/ChartDonut'

const ChartDemo1 = () => {
	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	]
	return (
		<div style={{ display: 'flex', flexWrap: 'wrap' }}>
			{months.map((i) => (
				<ChartDonut title={i} />
			))}
		</div>
	)
}

export default ChartDemo1
