import {
	useMantineTheme,
	Container,
	Text,
	Title,
	Grid,
	Card,
	Image,
	Badge,
	Button,
	Group,
} from '@mantine/core'

const SectionFour = () => {
	const theme = useMantineTheme()

	return (
		<section id="section-four">
			<Container>
				<Text color="black" align="center">
					<Title order={1} mb="30px">
						These features are really nice
					</Title>
				</Text>

				<Grid>
					<Grid.Col xs={12} sm={4} md={4} lg={4}>
						<Card shadow="sm" p="lg" style={{ height: '100%' }}>
							<Card.Section>
								<Image src={require('src/images/trail.jpg')} alt={'trail'} />
							</Card.Section>

							<Group
								position="apart"
								style={{ marginBottom: 5, marginTop: theme.spacing.sm }}
							>
								<Text weight={500}>Trail Map</Text>
								<Badge color="green" variant="light">
									Hiker/Runner
								</Badge>
							</Group>

							<Text size="sm">
								Trails Map Discover New Adventures Explore trails and meet
								locals for authenticity
							</Text>

							<Button variant="light" color="green" fullWidth mt="14px">
								Find out
							</Button>
						</Card>
					</Grid.Col>

					<Grid.Col xs={12} sm={4} md={4} lg={4}>
						<Card shadow="sm" p="lg" style={{ height: '100%' }}>
							<Card.Section>
								<Image
									src={require('src/images/connect.jpg')}
									alt={'sample1'}
								/>
							</Card.Section>

							<Group
								position="apart"
								style={{ marginBottom: 5, marginTop: theme.spacing.sm }}
							>
								<Text weight={500}>Hangout & Connect </Text>
								<Badge color="green" variant="light">
									Meet New Friends
								</Badge>
							</Group>

							<Text size="sm">
								Practice languages, and join local gatherings
							</Text>

							<Button variant="light" color="green" fullWidth mt="14px">
								Find out
							</Button>
						</Card>
					</Grid.Col>

					<Grid.Col xs={12} sm={4} md={4} lg={4}>
						<Card shadow="sm" p="lg" style={{ height: '100%' }}>
							<Card.Section>
								<Image
									src={require('src/images/tracking.jpg')}
									alt={'sample1'}
								/>
							</Card.Section>

							<Group
								position="apart"
								style={{ marginBottom: 5, marginTop: theme.spacing.sm }}
							>
								<Text weight={500}>Event Everyday</Text>
								<Badge color="green" variant="light">
									Event
								</Badge>
							</Group>

							<Text size="sm">Discover yourself and meet new people</Text>

							<Button variant="light" color="green" fullWidth mt="14px">
								Find out
							</Button>
						</Card>
					</Grid.Col>
				</Grid>
			</Container>
		</section>
	)
}

export default SectionFour
