import { convertParams } from 'src/utils/utils'
import axios from '../axios'
import { CONVERSIONROUTES } from 'src/router/Api'
export const getListConversation = async (params) => {
	return await axios.get(CONVERSIONROUTES.name, {
		params: convertParams(params),
	})
}
export const deleteConversation = async (id) => {
	return await axios.delete(`${CONVERSIONROUTES.name}/${id}/delete`)
}
