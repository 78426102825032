import { memo } from 'react'
import { MRT_ColumnDef } from 'mantine-react-table'
import { size } from 'src/variable/constant'
import CustomTooltip from 'src/components/CustomComponents/CustomTooltip'
import NetworkPageList from 'src/containers/Network/NetworkPageList'
import { Avatar } from '@mantine/core'

function NetworkPage() {
	const columns: MRT_ColumnDef[] = [
		{
			accessorKey: 'no',
			header: 'No',
			size: size.zero,
		},
		{
			accessorKey: 'avatar',
			header: 'Avatar',
			size: size.zero,
			Cell: ({ cell }) => {
				return <Avatar src={cell.getValue<string>()} radius={50} />
			},
		},
		{
			accessorKey: 'title',
			header: 'Title',
			size: size.zero,
			maxSize: size.text,
			Cell: ({ cell }) => {
				return (
					<CustomTooltip label={cell.getValue<string>()} maxWidth={size.long} />
				)
			},
		},

		{
			accessorKey: 'amount_of_user',
			header: 'Amount of user',
			size: size.zero,
		},
		{
			accessorKey: 'address',
			header: 'Address',
			size: size.zero,
			maxSize: size.text,
			Cell: ({ cell }) => {
				return (
					<CustomTooltip label={cell.getValue<string>()} maxWidth={size.long} />
				)
			},
		},
		{
			accessorKey: 'created_at',
			header: 'Created at',
			size: size.zero,
			maxSize: size.text,
		},
	]
	const fields = [
		{
			value: 'title',
			label: 'Title',
		},
	]
	return <NetworkPageList columns={columns} fields={fields} />
}

export default memo(NetworkPage)
