import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import cx from 'clsx'
import { Flex, Stack, Text } from '@mantine/core'
import { useListState } from '@mantine/hooks'
import classes from './ModalSort.module.scss'
import CustomModal from 'src/components/CustomComponents/CustomModal'
import { formatDateTime, textDecoration } from 'src/variable/constant'
import { memo, useEffect, useState } from 'react'
import CustomButton from 'src/components/CustomComponents/CustomButton'
import { useLoading } from 'src/context/LoadingContext'
import { useModal } from 'src/context/ModalContext'
import { getListCategoryTrails, sortCategory } from 'src/apis/categoryTrailsAPI'
import dayjs from 'dayjs'
interface DndListProps {
	onClose: () => void
	handleGetData: () => void
	body?: {
		fields?: any[]
		page?: number
		limit?: number
		where?: any
		order?: any
	}
	total?: number
	[key: string]: any
}

function ModalSort({
	onClose,
	dataSort,
	handleGetData,
	label,
	CustomComp,
	body,
	total = 0,
}: DndListProps) {
	const { openLoadingContext, closeLoadingContext } = useLoading()
	const { openModal, closeModal, openError } = useModal()
	const [state, handlers] = useListState([]) as any
	const [prototyData, setProtoryData] = useState([]) as any
	const items = state.map((item, index) => (
		<Draggable key={item?.id} index={index} draggableId={item?.id}>
			{(provided, snapshot) => (
				<div
					className={cx(classes.item, {
						[classes.itemDragging]: snapshot.isDragging,
					})}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					ref={provided.innerRef}
				>
					{CustomComp ? (
						CustomComp(item, index)
					) : (
						<>
							<Text className={classes.symbol}>{item?.no}</Text>
							<div>
								<Text
									sx={{
										...textDecoration,
										maxWidth: 300,
									}}
								>
									{item?.title}
								</Text>
								<Text c="dimmed" size="sm">
									{item?.created_at}
								</Text>
							</div>
						</>
					)}
				</div>
			)}
		</Draggable>
	))
	const handleSort = async () => {
		openLoadingContext()
		try {
			const orders = (state || []).map(({ id }, index) => {
				return {
					id,
					order: prototyData[index].order || index,
				}
			})
			await sortCategory({ orders })
		} catch (error) {
			console.log('error:', error)
			openError(error)
		} finally {
			closeLoadingContext()
			openModal({
				type: 'success',
				label: `Sort successfully `,
				handleClose: () => {
					handleGetData()
					closeModal()
					onClose()
				},
			})
		}
	}
	const handleConfirm = () => {
		openModal({
			type: 'confirm',
			label: `Do you want to sort them ?`,
			handleClose: closeModal,
			handleConfirm: handleSort,
		})
	}
	const handleLoadMoreItem = async () => {
		openLoadingContext()
		let data = []
		try {
			const results = await getListCategoryTrails({
				...body,
				limit: total,
				page: 1,
			})

			data = results?.data?.results?.objects?.rows?.map((item, index) => ({
				...item,
				no: index + 1 + body.limit * (body.page - 1),
				created_at: dayjs(item?.created_at).format(formatDateTime),
			}))
		} catch (error) {
			console.log('error:', error)
			openError(error)
		} finally {
			handlers.setState(data)
			setProtoryData(data)
			closeLoadingContext()
		}
	}

	useEffect(() => {
		handleLoadMoreItem()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return (
		<CustomModal
			opened={true}
			onClose={onClose}
			sx={{ '.mantine-Paper-root': { transform: 'none !important' } }}
		>
			<Stack>
				<Flex sx={{ fontSize: 25, fontWeight: 700 }} justify="center">
					{label || 'Modal Sort'}
				</Flex>

				<DragDropContext
					onDragEnd={({ destination, source }) =>
						handlers.reorder({
							from: source.index,
							to: destination?.index || 0,
						})
					}
				>
					<Droppable droppableId="dnd-list" direction="vertical">
						{(provided) => (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								{items}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
				<CustomButton
					variant="outlined"
					type="submit"
					onClick={() => handleConfirm()}
				>
					Confirm
				</CustomButton>
			</Stack>
		</CustomModal>
	)
}

export default memo(ModalSort)
