import { memo } from 'react'
import { MRT_ColumnDef } from 'mantine-react-table'
import OtpTrackingPageList from 'src/containers/OtpTracking'
import { size } from 'src/variable/constant'

function OtpTrackingPage() {
	const columns: MRT_ColumnDef[] = [
		{
			accessorKey: 'no',
			header: 'No',
			size: size.zero,
		},
		{
			accessorKey: 'phone',
			header: 'Phone',
			size: size.zero,
		},
		{
			accessorKey: 'gateway',
			header: 'Gate way',
			size: size.zero,
			maxSize: size.text,
		},
		{
			accessorKey: 'message',
			header: 'Description',
			size: size.zero,
			maxSize: size.text,
		},
		{
			accessorKey: 'status',
			header: 'Status',
			size: size.zero,
			maxSize: size.text,
		},
		// {
		// 	accessorKey: 'error_code',
		// 	header: 'Error code',
		// 	size: size.zero,
		// 	maxSize: size.text,
		// },
		{
			accessorKey: 'error_message',
			header: 'Error message',
			size: size.zero,
			maxSize: size.text,
		},
		{
			accessorKey: 'created_at',
			header: 'Send time',
			size: size.zero,
			maxSize: size.text,
			Cell: ({ cell }) => {
				return <>{cell.getValue<string>()} &nbsp;&nbsp;&nbsp;</>
			},
		},
	]
	const fields = [
		{
			value: 'phone',
			label: 'Phone',
		},
	]
	return <OtpTrackingPageList columns={columns} fields={fields} />
}

export default memo(OtpTrackingPage)
