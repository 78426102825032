import { convertParams } from 'src/utils/utils'
import axios from '../axios'
import { SERVICEROUTES } from 'src/router/Api'
export const getListService = async (params) => {
	return await axios.get(SERVICEROUTES.name, {
		params: convertParams(params),
	})
}
export const getOneService = async (id, params) => {
	return await axios.get(`${SERVICEROUTES.name}/${id}`, {
		params: convertParams(params),
	})
}

export const createService = async (payload) => {
	return await axios.post(SERVICEROUTES.name, payload)
}
export const updateService = async (id, payload) => {
	return await axios.put(`${SERVICEROUTES.name}/${id}`, payload)
}
export const deleteService = async (id) => {
	return await axios.delete(`${SERVICEROUTES.name}/${id}`)
}
