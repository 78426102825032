import React from 'react'
import { Stack, Typography } from '@mui/material'
import { GiConfirmed } from 'react-icons/gi'
import CustomModalClose from 'src/components/CustomComponents/CustomModalClose'
import CustomButton from 'src/components/CustomComponents/CustomButton'

function ModalSuccess({ open, handleClose, label }) {
	return (
		<CustomModalClose
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Stack alignItems="center" spacing={4} sx={{ margin: '20px 0' }}>
				<GiConfirmed size={80} color="#124984" />
				<Typography sx={{ textAlign: 'center' }}> {label}</Typography>
				<Stack direction="row" spacing={5}>
					<CustomButton
						variant="outlined"
						sx={{
							width: 100,
						}}
						onClick={handleClose}
					>
						Close
					</CustomButton>
				</Stack>
			</Stack>
		</CustomModalClose>
	)
}

export default React.memo(ModalSuccess)
