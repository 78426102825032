/* eslint-disable @typescript-eslint/no-unused-vars */
import { memo, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { type PropsApexChart } from 'src/interfaces'

const ChartMix = () => {
	const max = 430
	const percents = [17, 31, 22, 22, 12, 16, 23, 42, 35, 27, 43, 22]
	const [state, setState] = useState<PropsApexChart>({
		series: [
			{
				name: 'Percent line',
				type: 'line',
				data: [17, 31, 22, 22, 12, 16, 23, 42, 35, 27, 43, 22],
			},
			{
				name: 'User',
				type: 'bar',
				data: [420, 305, 207, 403, 202, 107, 301, 202, 202, 102, 106, 203],
				group: 'budget',
				zIndex: 1,
			},
			{
				name: 'Total user',
				type: 'bar',
				data: [230, 402, 305, 270, 430, 202, 107, 310, 220, 202, 102, 160],
				group: 'budget',
				zIndex: 100,
			},
			{
				name: 'Percent col',
				type: 'bar',
				group: 'budget',
				data: percents.map((i) => (i * max) / 200),
			},
		],
		options: {
			chart: {
				type: 'bar',
				height: 350,
				stacked: true,
			},

			stroke: {
				width: [1, 1, 1, 1],
			},
			title: {
				text: 'Traffic Sources',
			},
			dataLabels: {
				enabled: true,
				formatter: (val, opts) => {
					const { seriesIndex, dataPointIndex } = opts
					console.log(opts)
					if (seriesIndex === 3) return `${percents[dataPointIndex]}%`
					if (seriesIndex) return String(val)

					return `${val}%`
				},
			},
			labels: [
				'W1 - W2',
				'W2 - W3',
				'W3 - W4',
				'W4 - W5',
				'W5 - W6',
				'W6 - W7',
				'W7 - W8',
				'W8 - W9',
				'W9 - W10',
				'W10 - W11',
				'W11 - W12',
				'W12 - W1',
			],

			yaxis: [
				{
					title: {
						text: 'Line',
					},
					labels: {
						formatter: (val, opts) => {
							const { seriesIndex, dataPointIndex } = opts
							if (seriesIndex === 3) return `${percents[dataPointIndex]}%`

							if (seriesIndex) return String(val)
							return `${val} %`
						},
					},
				},
				{
					opposite: true,
					title: {
						text: 'Col',
					},
				},
			],
		},
	})

	return (
		<div>
			<div id="chart">
				<ReactApexChart
					options={state.options}
					series={state.series}
					type="line"
					height={350}
				/>
			</div>
			<div id="html-dist"></div>
		</div>
	)
}

export default memo(ChartMix)
