import { MRT_ColumnDef } from 'mantine-react-table'
import { memo } from 'react'
import ProvincePageList from 'src/containers/Province'

function ProvincePage() {
	const columns: MRT_ColumnDef[] = [
		{
			accessorKey: 'province',
			header: 'Province',
		},
		{
			accessorKey: 'amount',
			header: 'Amount of user',
		},

		{
			accessorKey: 'province1',
			header: 'Province',
		},
		{
			accessorKey: 'amount1',
			header: 'Amount of user',
		},

		{
			accessorKey: 'province2',
			header: 'Province',
		},
		{
			accessorKey: 'amount2',
			header: 'Amount of user',
		},
	]
	const fields = [
		{
			value: 'province',
			label: 'Province',
		},
	]
	return <ProvincePageList columns={columns} fields={fields} />
}

export default memo(ProvincePage)
