import { memo, useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { type PropsApexChart } from 'src/interfaces'

const ApexChart = () => {
	const [state, setState] = useState<PropsApexChart>({
		series: [
			{
				name: '1',
				data: [31, 40, 43, 51, 52, 69, 110],
			},
			{
				name: '2',
				data: [null, 40, 45, 52, 54, 72, 141],
			},
			{
				name: '3',
				data: [null, null, 45, 55, 62, 89, 150],
			},
			{
				name: '4',
				data: [null, null, null, 55, 76, 92, 171],
			},
			{
				name: '5',
				data: [null, null, null, null, 76, 110, 189],
			},
		],
		options: {
			chart: {
				height: 350,
				type: 'area',
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				curve: 'smooth',
			},
			xaxis: {
				// type: 'datetime',
				categories: [
					'Week 1',
					'Week 2',
					'Week 3',
					'Week 4',
					'Week 5',
					'Week 6',
					'Week 7',
				],
			},
			tooltip: {
				x: {
					format: 'dd/MM/yy HH:mm',
				},
			},
		},
	})
	useEffect(() => {
		setState((pre) => pre)
	}, [])
	return (
		<div>
			<div id="chart">
				<ReactApexChart
					options={state.options}
					series={state.series}
					type="area"
					height={350}
				/>
			</div>
		</div>
	)
}
export default memo(ApexChart)
