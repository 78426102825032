import { MRT_ColumnDef } from 'mantine-react-table'
import { memo } from 'react'
import CustomTooltip from 'src/components/CustomComponents/CustomTooltip'
import ServicePageList from 'src/containers/Service'
import { size } from 'src/variable/constant'
import { service } from 'src/variable/serviceData'

function ServicePage() {
	const columns: MRT_ColumnDef[] = [
		{
			accessorKey: 'no',
			header: 'No',
			size: size.zero,
		},
		{
			accessorKey: 'title',
			header: 'Title',
			size: size.zero,
			maxSize: size.text,
			Cell: ({ cell }) => {
				return (
					<CustomTooltip label={cell.getValue<string>()} maxWidth={size.text} />
				)
			},
		},
		{
			accessorKey: 'value',
			header: 'Content',
			size: size.zero,
			maxSize: size.text,
			Cell: ({ cell }) => {
				return (
					<CustomTooltip
						label={cell.getValue<string>()}
						maxWidth={size.medium}
					/>
				)
			},
		},
		{
			accessorKey: 'type',
			header: 'Type',
			size: size.zero,
			maxSize: size.text,
			Cell: ({ cell }) => <> {service[cell.getValue<string>()] || ''}</>,
		},
		{
			accessorKey: 'created_at',
			header: 'Created at',
			size: size.zero,
			maxSize: size.text,
		},
	]
	const fields = [
		{
			value: 'title',
			label: 'Title',
		},
		{
			value: 'value',
			label: 'Content',
		},
	]
	return <ServicePageList columns={columns} fields={fields} />
}

export default memo(ServicePage)
