import React from 'react'

import { pathUrl } from 'src/variable/constant'

import AuthenLayout from 'src/layouts/AuthenLayout'
import DefaultLayout from 'src/layouts/DefaultLayout'
import Home from 'src/pages/Home'
import LoginPage from 'src/pages/LoginPage'
import NotFound from 'src/pages/NotFound'
import SettingsPage from 'src/pages/SettingsPage'
import SettingDetailPage from 'src/pages/SettingsPage/[id]'
import UsersPage from 'src/pages/UsersPage'
import UserDetailPage from 'src/pages/UsersPage/[id]'
import GuidersPage from 'src/pages/GuidersPage'
import GuiderDetailPage from 'src/pages/GuidersPage/[id]'
import TermsAndPolicy from 'src/pages/TermsAndPolicy'
import LandingPage from 'src/pages/LandingPage'
import CategoryTrailsPage from 'src/pages/CategoryTrailsPage'
import CategoryTrailsDetailPage from 'src/pages/CategoryTrailsPage/[id]'
import TopicsPage from 'src/pages/TopicsPage'
import SubTopicsPage from 'src/pages/TopicsPage/[id]/SubTopicsPage'
import ConfigPhonePage from 'src/pages/ConfigPhonePage'
import OtpTrackingPage from 'src/pages/OtpTrackingPage'
import NotificationPage from 'src/pages/NotificationPage'
import ServicePage from 'src/pages/ServicePage'
import ConversationPage from 'src/pages/ConversationPage'
import ProvincePage from 'src/pages/Province'
import NetworkPage from 'src/pages/NetworkPage'
import ChartDemo2 from 'src/pages/ChartDemo2'
import ChartDemo1 from 'src/pages/ChartDemo1'

const publicRoute = [
	// {
	// 	path: '/',
	// 	component: Home,
	// 	layout: DefaultLayout,
	// },
	// landing-page
	{
		path: '/',
		component: LandingPage,
		layout: React.Fragment,
	},

	{
		path: '/dashboard',
		component: Home,
		layout: DefaultLayout,
	},
	{
		path: '/ChartDemo1',
		component: ChartDemo1,
		layout: DefaultLayout,
	},
	{
		path: '/ChartDemo2',
		component: ChartDemo2,
		layout: DefaultLayout,
	},
	{
		path: pathUrl.province,
		component: ProvincePage,
		layout: DefaultLayout,
	},
	{
		path: '/users',
		component: UsersPage,
		layout: DefaultLayout,
	},
	{
		path: '/users/:id',
		component: UserDetailPage,
		layout: DefaultLayout,
	},
	{
		path: '/guiders',
		component: GuidersPage,
		layout: DefaultLayout,
	},
	{
		path: '/guiders/:id',
		component: GuiderDetailPage,
		layout: DefaultLayout,
	},
	{
		path: '/categorytrails',
		component: CategoryTrailsPage,
		layout: DefaultLayout,
	},
	{
		path: '/categorytrails/:id',
		component: CategoryTrailsDetailPage,
		layout: DefaultLayout,
	},
	{
		path: pathUrl.topics,
		component: TopicsPage,
		layout: DefaultLayout,
	},
	{
		path: `${pathUrl.topics}/:id`,
		component: SubTopicsPage,
		layout: DefaultLayout,
	},
	{
		path: pathUrl.configphone,
		component: ConfigPhonePage,
		layout: DefaultLayout,
	},
	{
		path: pathUrl.otpTracking,
		component: OtpTrackingPage,
		layout: DefaultLayout,
	},
	{
		path: pathUrl.notification,
		component: NotificationPage,
		layout: DefaultLayout,
	},
	{
		path: pathUrl.service,
		component: ServicePage,
		layout: DefaultLayout,
	},
	{
		path: pathUrl.conversation,
		component: ConversationPage,
		layout: DefaultLayout,
	},
	{
		path: pathUrl.network,
		component: NetworkPage,
		layout: DefaultLayout,
	},
	// {
	// 	path: '/topics/:id',
	// 	component: SubTopicsPage,
	// 	layout: DefaultLayout,
	// },
	{
		path: '/settings',
		component: SettingsPage,
		layout: DefaultLayout,
	},
	{
		path: '/settings/:id',
		component: SettingDetailPage,
		layout: DefaultLayout,
	},
	//policy
	{
		path: '/terms',
		component: TermsAndPolicy,
		layout: React.Fragment,
	},
	//policy
	{
		path: '/policy',
		component: TermsAndPolicy,
		layout: React.Fragment,
	},
	// login
	{
		path: '/login',
		component: LoginPage,
		layout: AuthenLayout,
	},
	{ path: '*', component: NotFound, layout: DefaultLayout },
]
const privateRoute = [] // sau nafy co be tinh tiep

export { publicRoute, privateRoute }
