import ChartArea from 'src/components/Chart/ChartArea'
import ChartMix from 'src/components/Chart/ChartMix'

const ChartDemo2 = () => {
	return (
		<div style={{ width: '100%' }}>
			<ChartArea />
			<ChartMix />
		</div>
	)
}

export default ChartDemo2
