/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from '@mantine/form'
import { Flex, Stack, Switch, TextInput } from '@mantine/core'
import CustomButton from 'src/components/CustomComponents/CustomButton'
import { useModal } from 'src/context/ModalContext'
import { useLoading } from 'src/context/LoadingContext'

import CustomModal from 'src/components/CustomComponents/CustomModal'
import CustomSelect from 'src/components/CustomComponents/CustomSelect'
import { createConfigPhone, updateConfigPhone } from 'src/apis/configPhoneAPI'

interface ModalCreateCategoryTrailsProps {
	open: { open: boolean; row: any }
	onClose: () => void
	handleGetData: () => void
	pagination: {
		pageIndex: number
		pageSize: number
	}
	handleChangePage: (_value: number) => void
	[key: string]: any
}
const data = ['PREFIX', 'PHONE']
const regex = /^[0-9+]+$/

function ModalCreatePhone({
	open: { open, row },
	onClose,
	handleGetData,
	pagination,
	handleChangePage,
}: ModalCreateCategoryTrailsProps) {
	const { openLoadingContext, closeLoadingContext } = useLoading()

	const form = useForm({
		initialValues: {
			value: row?.value || '',
			type: row?.type || 'PREFIX',
		},
		validate: {
			value: (value) => {
				if (!value.trim()) return 'Phone cannot be empty'
				if (value.length > 15) return 'Phone must be under 15 characters long'
				if (!regex.test(value.trim())) return 'Phone valid'
				return null
			},
		},
		validateInputOnBlur: true,
		validateInputOnChange: true,
	})
	const [status, setStatus] = useState(row?.status || false)
	const { openModal, closeModal, openError } = useModal()
	const handleCreateUpdatePhone = useCallback(async (body) => {
		openLoadingContext()
		try {
			const res = await (row
				? updateConfigPhone(row?.id, body)
				: createConfigPhone(body))
			if (res?.data?.code === 200) {
				if (!row && pagination.pageIndex !== 1) {
					await handleChangePage(1)
				} else {
					await handleGetData()
				}
				openModal({
					type: 'success',
					label: `Phone has been ${row ? 'edited' : 'created'} successfully `,
					handleClose: () => {
						closeModal()
						onClose()
					},
				})
			}
		} catch (error) {
			console.log(
				'🧑‍🏭 TrieuNinhHan ~ file: ModalCreatePhone.tsx:68 ~ handleSubmit ~ error:',
				error,
			)
			openError(error)
		} finally {
			closeLoadingContext()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleSubmit = useCallback(async (values) => {
		console.log(
			'🍁🍁🍁 TrieuNinhHan ~ file: ModalCreatePhone.tsx:90 ~ handleSubmit ~ values:',
			values,
		)
		const body = {
			...values,
		}
		openModal({
			type: 'confirm',
			label: `Do you want to ${row ? 'edit' : 'create'} phone ?`,
			handleClose: () => closeModal(),
			handleConfirm: () => {
				handleCreateUpdatePhone(body)
			},
		})
	}, [])
	useEffect(() => {
		if (row?.id) {
			form.setValues({
				value: row?.value || '',
				type: row?.type || 'PREFIX',
			})
			setStatus(row?.status || false)
		}
	}, [JSON.stringify(row)])
	useEffect(() => {
		if (form?.values?.type !== 'PREFIX' && !row?.id) {
			form.setFieldValue('value', '+84')
		}
	}, [form?.values?.type])
	return (
		<CustomModal opened={open} onClose={onClose}>
			<form
				onSubmit={form.onSubmit((values) =>
					handleSubmit({ ...values, status }),
				)}
			>
				<Stack spacing="0.75rem" sx={{ marginBottom: '20px' }}>
					<Flex sx={{ fontSize: 25, fontWeight: 700 }} justify="center">
						{row ? 'Edit' : 'Create'}
					</Flex>
					<TextInput
						autoComplete="off"
						withAsterisk
						key="value"
						label={'Phone'}
						name={'value'}
						placeholder={`Phone`}
						maxLength={50}
						{...form.getInputProps('value')}
					/>
					<CustomSelect
						label="Type"
						key="type"
						name="type"
						data={data}
						sx={{ height: 'unset' }}
						{...form.getInputProps('type')}
					/>
					<label htmlFor="switch" style={{ cursor: 'pointer' }}>
						<label style={{ fontWeight: 500 }}>Status</label>
						<Switch
							onLabel="Active"
							offLabel="InActive"
							id="switch"
							radius="sm"
							size="xl"
							sx={{
								'.mantine-Switch-body': {
									'.mantine-Switch-track': {
										cursor: 'pointer',
									},
								},
								'.mantine-Switch-trackLabel': {
									fontSize: '0.875rem',
								},
							}}
							checked={status}
							onChange={(e) => setStatus(e.target.checked)}
						/>
					</label>
					<CustomButton variant="outlined" type="submit">
						{row ? 'Edit' : 'Create'}
					</CustomButton>
				</Stack>
			</form>
		</CustomModal>
	)
}

export default React.memo(ModalCreatePhone)
