import React from 'react'
import { Stack, Typography } from '@mui/material'
import CustomModalClose from 'src/components/CustomComponents/CustomModalClose'
import CustomButton from 'src/components/CustomComponents/CustomButton'
import { MdOutlineError } from 'react-icons/md'

function ModalError({ open, handleClose, error }) {
	let message = error?.response?.data?.message || error?.message
	let code = error?.response?.data?.code || 0
	if (typeof message !== 'string') {
		message = 'Unknow error'
	}
	if (code === 409) {
		message = 'Login expired, please login again 🍁'
	}
	const onClose = () => {
		handleClose()
		if (code === 409) {
			window.location.href = '/login'
		}
	}
	return (
		<CustomModalClose
			open={open}
			onClose={onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Stack alignItems="center" spacing={4} sx={{ margin: '20px 0' }}>
				<MdOutlineError size={80} color="red" />
				<Typography sx={{ textAlign: 'center' }}>{message}</Typography>
				<Stack direction="row" spacing={5}>
					<CustomButton
						variant="outlined"
						sx={{
							width: 100,
						}}
						onClick={onClose}
					>
						Close
					</CustomButton>
				</Stack>
			</Stack>
		</CustomModalClose>
	)
}

export default React.memo(ModalError)
