/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from '@mantine/form'
import { Flex, Modal, Stack, TextInput } from '@mantine/core'
import { useModal } from 'src/context/ModalContext'
import { getOneCategory } from 'src/apis/categoryTrailsAPI'
import { useLoading } from 'src/context/LoadingContext'
import TrailItem from 'src/components/Trail/TrailItem'
function ModalDetailCategoryTrails({ onClose, id }) {
	const { openLoadingContext, closeLoadingContext } = useLoading()
	const { openError } = useModal()
	const [trails, setTrails] = useState([])

	const form = useForm({
		initialValues: {
			title: '',
			description: '',
		},

		validateInputOnBlur: true,
		validateInputOnChange: true,
	})

	const handleGetOne = useCallback(async () => {
		openLoadingContext()
		try {
			const res = await getOneCategory(id, {
				fields: [
					'$all',
					{
						category_trails: [
							'id',
							{
								trail: [
									'id',
									'user_id',
									'title',
									'start_address',
									'address',
									'description',
									'images',
									'level',
									'time_in_seconds',
									'height',
									'length',
									{ user: ['name', 'account_type'] },
								],
							},
						],
					},
				],
			})
			if (res?.data?.code === 200) {
				const { object } = res?.data?.results
				form.setValues({
					title: object?.title || '',
					description: object?.description || '',
				})
				setTrails(
					object?.category_trails?.map((trail) => ({ ...trail?.trail })) || [],
				)
			}
		} catch (error) {
			console.log(
				'🧑‍🏭 TrieuNinhHan ~ file: ModalEditCategoryTrails.tsx:106 ~ handleGetOne ~ error:',
				error,
			)
			openError(error)
		} finally {
			closeLoadingContext()
		}
	}, [JSON.stringify(id)])
	useEffect(() => {
		handleGetOne()
	}, [id])
	return (
		<Stack sx={{ width: '100%' }}>
			<Modal
				opened={true}
				onClose={onClose}
				size="auto"
				centered
				sx={{
					'.mantine-Modal-inner ': {
						width: '100%',
						padding: 200,
						boxSizing: 'border-box',
						paddingLeft: 260,
						'@media (max-width:900px)': {
							padding: 20,
						},
					},
				}}
			>
				<Stack spacing="0.75rem">
					<Flex sx={{ fontSize: 25, fontWeight: 700 }} justify="center">
						Detail category trails
					</Flex>
					<TextInput
						autoComplete="off"
						withAsterisk
						key="title"
						label={'Title'}
						name={'title'}
						placeholder={"Category's title"}
						width={'100%'}
						maxLength={50}
						disabled
						{...form.getInputProps('title')}
					/>
					<TextInput
						autoComplete="off"
						withAsterisk
						key="description"
						label={'Description'}
						name={'name'}
						placeholder={"Category's description"}
						maxLength={255}
						{...form.getInputProps('description')}
						disabled
					/>

					<Flex
						sx={{
							flexWrap: 'wrap',
						}}
						gap={10}
					>
						{trails?.map((trail) => (
							<TrailItem
								trail={trail}
								key={trail?.id}
								trails={trails}
								setTrails={setTrails}
								disabled
								sx={{
									'@media (max-width:1500px)': {
										maxWidth: 'calc(50% - 10px)',
										minWidth: 'calc(50% - 10px)',
									},
									'@media (max-width:1000px)': {
										maxWidth: 'calc(100% - 10px)',
										minWidth: 'calc(100% - 10px)',
									},
									'@media (max-width:900px)': {
										maxWidth: 'calc(50% - 10px)',
										minWidth: 'calc(50% - 10px)',
									},
									'@media (max-width:700px)': {
										maxWidth: 'calc(100% - 10px)',
										minWidth: 'calc(100% - 10px)',
									},
								}}
							/>
						))}
					</Flex>
				</Stack>
			</Modal>
		</Stack>
	)
}

export default React.memo(ModalDetailCategoryTrails)
