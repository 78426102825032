/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { Flex, Stack } from '@mantine/core'
import dayjs from 'dayjs'
import { MRT_ColumnDef, MRT_PaginationState } from 'mantine-react-table'
import { deleteUser, getListUser } from 'src/apis/userAPI'
import CustomClearInput from 'src/components/CustomComponents/CustomClearInput'
import CustomSelect from 'src/components/CustomComponents/CustomSelect'
import TableComponent from 'src/components/TableComponent'
import { FieldType, UserData } from 'src/interfaces'
import { filterUserPageList } from 'src/utils/filter'
import { color, formatDateTime } from 'src/variable/constant'
import CustomButton from 'src/components/CustomComponents/CustomButton'
import ModalCreateGuider from './ModalCreateGuider'
import { useModal } from 'src/context/ModalContext'
import { useLoading } from 'src/context/LoadingContext'

interface UserPageListProps {
	columns: MRT_ColumnDef[]
	type?: string
	fields: FieldType[]
	handleDetail: (row: any) => void
	selectOption?: {
		label: string
		value: any
	}[]
	[key: string]: any
}

function UserPageList({
	columns,
	type = 'USER',
	fields,
	handleDetail,
	selectOption = null,
}: UserPageListProps) {
	const { openError, openModal } = useModal()
	const { openLoadingContext, closeLoadingContext } = useLoading()
	const [data, setData] = useState<UserData[]>([])
	const [pagination, setPagination] = useState<MRT_PaginationState>({
		pageIndex: 1,
		pageSize: 10,
	})
	const [value, setValue] = useState({
		field: fields[0].value,
		value: null,
		deleted_at: null,
	})
	const [total, setTotal] = useState<number>(0)
	const [loading, setLoading] = useState<boolean>(false)
	const [open, setOpen] = useState<boolean>(false)

	const handleGetData = useCallback(async () => {
		let arr: UserData[] = []
		let count: number = 0
		let filter = {}
		setLoading(true)
		try {
			filter = filterUserPageList(value)
			const res = await getListUser({
				fields: ['$all', type !== 'GUIDER' ? '$paranoid' : ''],
				page: pagination.pageIndex,
				limit: pagination.pageSize,
				where: { ...filter, account_type: type },
			})
			arr = res?.data?.results?.objects?.rows?.map((item, index) => ({
				no: index + 1 + pagination.pageSize * (pagination.pageIndex - 1),
				...item,
				created_at: dayjs(item?.created_at).format(formatDateTime),
			}))
			count = res?.data?.results?.objects?.count
		} catch (error) {
			openError(error)
		} finally {
			setData(arr)
			setTotal(count)

			setTimeout(() => {
				setLoading(false)
			}, 500)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(pagination), JSON.stringify(value)])
	const handleSetValue = (e, key) => {
		if (key === 'field' && value.value === '') {
			setValue((pre) => ({ ...pre, [key]: e, value: null }))
		} else {
			setValue((pre) => ({ ...pre, [key]: e }))
		}
	}
	const handleChangePage = useCallback((value: number) => {
		setPagination((pre) => ({
			...pre,
			pageIndex: value,
		}))
	}, [])
	const handleDelete = useCallback(
		async (row: any) => {
			openLoadingContext()
			try {
				const res = await deleteUser(row?.original?.id)
				if (res?.data?.code === 200) {
					await handleGetData()
					openModal({ type: 'success', label: 'Deleted successfully !!!' })
				}
			} catch (error) {
				openError(error)
			} finally {
				closeLoadingContext()
			}
		},
		[handleGetData],
	)
	const handleConfirmDelete = useCallback(
		(row: any) => {
			openModal({
				type: 'confirm',
				label: 'Do you want to delete this account ?',
				handleConfirm: () => {
					handleDelete(row)
				},
			})
		},
		[handleDelete],
	)
	useEffect(() => {
		handleGetData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(pagination)])
	useEffect(() => {
		let timer
		if (value.value !== null || value.deleted_at !== null) {
			timer = setTimeout(() => {
				if (pagination.pageIndex === 1) {
					handleGetData()
				} else handleChangePage(1)
			}, 500)
		}
		return () => clearTimeout(timer)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(value)])
	return (
		<Stack sx={{ width: '100%' }}>
			<Flex
				gap={8}
				justify="space-between"
				sx={{ flexWrap: 'wrap' }}
				align="center"
			>
				<Flex gap={8} sx={{ flexWrap: 'wrap' }}>
					<CustomSelect
						value={value.field}
						data={fields}
						onChange={(e) => {
							handleSetValue(e, 'field')
						}}
					/>
					<CustomClearInput
						value={value.value}
						onChange={(e) => handleSetValue(e, 'value')}
						placeholder={'Search'}
					/>
					{selectOption && (
						<CustomSelect
							data={selectOption}
							value={value.deleted_at}
							placeholder="State account"
							onChange={(e) => handleSetValue(e, 'deleted_at')}
						/>
					)}
				</Flex>
				{type !== 'GUIDER' && (
					<Flex
						justify="flex-end"
						sx={{ fontSize: 20, fontWeight: 600, color: color.CmsPrimary }}
					>
						<div>Total: {total || ''}</div>
					</Flex>
				)}
				{type === 'GUIDER' && (
					<CustomButton
						variant="outlined"
						sx={{ height: '36px', whiteSpace: 'nowrap', minWidth: '240px' }}
						onClick={() => setOpen(true)}
					>
						Create
					</CustomButton>
				)}
			</Flex>

			<TableComponent
				columns={columns}
				total={total}
				data={data}
				pagination={pagination}
				loading={loading}
				handleChangePage={handleChangePage}
				handleDetail={handleDetail}
				handleDelete={handleConfirmDelete}
			/>
			{open && (
				<ModalCreateGuider
					open={open}
					onClose={() => setOpen(false)}
					handleGetData={handleGetData}
					pagination={pagination}
					handleChangePage={handleChangePage}
				/>
			)}
		</Stack>
	)
}

export default React.memo(UserPageList)
