/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	Button,
	Badge,
	Burger,
	Drawer,
	Code,
	Title,
	Anchor,
	Image,
} from '@mantine/core'
import { UnstyledButton, Group, Avatar } from '@mantine/core'
import React from 'react'
import { Link } from 'react-scroll'

const Header = () => {
	//const theme = useMantineTheme();
	const [opened, setOpened] = React.useState(false)
	const title = opened ? 'Close navigation' : 'Open navigation'

	return (
		<header>
			<div className="content-desktop">
				<div>
					<Badge size="xl" radius={10} color="green">
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								padding: '10px',
							}}
						>
							<Image
								src={require('src/images/logo.png')}
								alt={'logo'}
								style={{ width: '20px', marginBottom: '0!important' }}
							/>
							UniVini
						</div>
					</Badge>
				</div>
				<div className="navbar">
					<div className="navbar-item">
						<Link to="section-one" smooth duration={500}>
							Carousel
						</Link>
					</div>
					<div className="navbar-item">
						<Link to="section-four" smooth duration={500}>
							Features
						</Link>
					</div>
					<div className="navbar-item">
						<Link to="section-five" smooth duration={500}>
							FAQS
						</Link>
					</div>
					<div className="navbar-item">
						<a href="/login">Admin</a>
					</div>
				</div>
			</div>

			<div className="content-mobile">
				<div className="burger-button">
					<Burger
						opened={opened}
						onClick={() => setOpened((o) => !o)}
						title={title}
						size="sm"
					/>
				</div>

				<div className="navbar">
					<div className="navbar-item">
						<Link to="section-one" smooth duration={500}>
							Carousel
						</Link>
					</div>
					<div className="navbar-item">
						<Link to="section-four" smooth duration={500}>
							Features
						</Link>
					</div>
					<div className="navbar-item">
						<Link to="section-five" smooth duration={500}>
							FAQS
						</Link>
					</div>
					<div className="navbar-item">
						<a href="/login">Admin</a>
					</div>
				</div>
			</div>
		</header>
	)
}

export default Header

const redirectToLink = (link: string): void => {
	window.open(link, '_blank')
}
