import axios from '../axios'
import { UPLOADROUTES } from 'src/router/Api'
export const uploadProgress = async (
	body,
	onUploadProgress,
	signal = null as any,
) => {
	try {
		const response = await axios.post(`${UPLOADROUTES.name}`, body, {
			onUploadProgress: (progressEvent) => {
				if (onUploadProgress) {
					onUploadProgress(progressEvent)
				}
			},
			...(signal ? { signal: signal } : {}),
		})
		return response.data
	} catch (error) {
		console.error('Upload failed:', error)
		throw error // Rethrow the error for handling in the caller function
	}
}
