import React, { useCallback, useEffect, useState } from 'react'
import { MRT_ColumnDef, MRT_PaginationState } from 'mantine-react-table'
import { CategoryTrailsData, FieldType } from 'src/interfaces'
import { useModal } from 'src/context/ModalContext'
import { useLoading } from 'src/context/LoadingContext'
import {
	deleteCategory,
	getListCategoryTrails,
	getOneCategory,
	updateCategory,
} from 'src/apis/categoryTrailsAPI'
import { Flex, Stack, Tooltip } from '@mantine/core'
import CustomSelect from 'src/components/CustomComponents/CustomSelect'
import CustomClearInput from 'src/components/CustomComponents/CustomClearInput'
import CustomButton from 'src/components/CustomComponents/CustomButton'
import TableComponent from 'src/components/TableComponent'
import dayjs from 'dayjs'
import { color, formatDateTime, pathUrl } from 'src/variable/constant'
import { filterNormal } from 'src/utils/filter'
import ModalDetailCategoryTrails from '../ModalDetailCategoryTrails'
import ModalCreateTopic from '../ModalCreateTopic'
import CustomGoBack from 'src/components/CustomComponents/CustomGoBack'
import { useNavigate } from 'react-router-dom'
import { IconEye, IconReplace, IconEyeX } from '@tabler/icons-react'
import ModalMoveTopic from 'src/components/Modal/ModalMoveTopic'
import ModalSort from 'src/components/Modal/ModalSort'

interface SubTopicsPageListProps {
	id: string
	columns: MRT_ColumnDef[]
	fields: FieldType[]
	[key: string]: any
}

function SubTopicsPageList({
	id,
	columns,
	fields,
	handleEdit,
	openTopic,
	setOpenTopic,
}: SubTopicsPageListProps) {
	const { openError, openModal } = useModal()
	const { openLoadingContext, closeLoadingContext } = useLoading()
	const navigate = useNavigate()
	const [data, setData] = useState<CategoryTrailsData[]>([])
	const [parent, setParent] = useState<CategoryTrailsData>(null)
	const [pagination, setPagination] = useState<MRT_PaginationState>({
		pageIndex: 1,
		pageSize: 10,
	})
	const [value, setValue] = useState({
		field: fields[0].value,
		value: null,
	})
	const [total, setTotal] = useState<number>(0)
	const [loading, setLoading] = useState<boolean>(false)

	const [openDetail, setOpenDetail] = useState({
		open: false,
		row: {} as any,
	})
	const [openModalMove, setOpenModalMove] = useState({
		open: false,
		row: {} as any,
	})
	const [openModalSort, setOpenModalSort] = useState({ open: false, data: [] })
	const [body, setBody] = useState({})

	const handleGetData = useCallback(async () => {
		let arr: CategoryTrailsData[] = []
		let count: number = 0
		let filter = {}
		setLoading(true)
		try {
			filter = filterNormal(value)
			const _body = {
				fields: ['$all'],
				page: pagination.pageIndex,
				limit: pagination.pageSize,
				where: { ...filter, parent_id: id },
				order: [['order', 'asc']],
			}
			setBody(_body)
			const res = await getListCategoryTrails(_body)
			arr = res?.data?.results?.objects?.rows?.map((item, index) => ({
				...item,
				no: index + 1 + pagination.pageSize * (pagination.pageIndex - 1),
				created_at: dayjs(item?.created_at).format(formatDateTime),
			}))
			count = res?.data?.results?.objects?.count
		} catch (error) {
			openError(error)
		} finally {
			setData(arr)
			setTotal(count)

			setTimeout(() => {
				setLoading(false)
			}, 500)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(pagination), JSON.stringify(value)])
	const handleGetParent = useCallback(async () => {
		try {
			const res = await getOneCategory(id)
			console.log(
				'🍂🍂🍂🍂🍂 TrieuNinhHan ~ file: SubTopicsPageList.tsx:99 ~ handleGetParent ~ res:',
				res,
			)
			if (res?.data?.code === 200) {
				setParent(res?.data?.results?.object || {})
			}
		} catch (error) {
			openError(error)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const handleSetValue = (e, key) => {
		if (key === 'field' && value.value === '') {
			setValue((pre) => ({ ...pre, [key]: e, value: null }))
		} else {
			setValue((pre) => ({ ...pre, [key]: e }))
		}
	}
	const handleChangePage = useCallback((value: number) => {
		setPagination((pre) => ({
			...pre,
			pageIndex: value,
		}))
	}, [])
	const handleUpdateCategoryTrails = useCallback(async ({ id, status }) => {
		openLoadingContext()
		try {
			const res = await updateCategory(id, { status: !status })
			if (res?.data?.code === 200) {
				setData((pre) =>
					pre.map((item) =>
						item.id === id ? { ...item, status: !status } : item,
					),
				)
				openModal({
					type: 'success',
					label: `Sub topic has been ${status ? 'hide' : 'show'} successfully `,
				})
			}
		} catch (error) {
			console.log(
				'🧑‍🏭 TrieuNinhHan ~ file: ModalCreateTopic.tsx:68 ~ handleSubmit ~ error:',
				error,
			)
			openError(error)
		} finally {
			closeLoadingContext()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const handleOpenUpdateStatus = useCallback(({ id, status }) => {
		openModal({
			type: 'confirm',
			label: (
				<div>Do you want to {status ? 'hidden' : 'shown'} this sub topic?</div>
			),
			handleConfirm: () => {
				handleUpdateCategoryTrails({ id, status })
			},
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const _renderCustomActions = useCallback(
		(row) => {
			const { id, status } = row?.original
			const Icon = status ? IconEyeX : IconEye
			const label = status ? 'Hide' : 'Show'
			return (
				<>
					<Stack sx={{ order: 10 }}>
						<Tooltip label="Move">
							<IconReplace
								style={{ cursor: 'pointer' }}
								onClick={() => setOpenModalMove({ open: true, row: row })}
							/>
						</Tooltip>
					</Stack>
					<Stack sx={{ order: 40 }}>
						<Tooltip label={label}>
							<Icon
								style={{ cursor: 'pointer' }}
								onClick={() => handleOpenUpdateStatus({ id, status })}
							/>
						</Tooltip>
					</Stack>
				</>
			)
		},
		[handleOpenUpdateStatus],
	)

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleDetail = useCallback((row: any) => {
		setOpenDetail({
			open: true,
			row: row,
		})
	}, [])
	const handleDeleteCategoryTrail = useCallback(
		async (id) => {
			openLoadingContext()
			try {
				const res = await deleteCategory(id)
				if (res?.data?.code === 200) {
					await handleGetData()
					openModal({
						type: 'success',
						label: `You have successfully deleted sub topic !`,
					})
				}
			} catch (error) {
				console.log(
					'🧑‍🏭 TrieuNinhHan ~ file: CategoryTrailsPageList.tsx:103 ~ handleDeleteCategoryTrail ~ error:',
					error,
				)
				openError(error)
			} finally {
				closeLoadingContext()
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[handleGetData],
	)
	const handleDelete = useCallback(
		(row: any) => {
			openModal({
				type: 'confirm',
				label: <div>Do you want to delete this sub topic?</div>,
				handleConfirm: () => {
					handleDeleteCategoryTrail(row?.original?.id)
				},
			})
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[handleDeleteCategoryTrail],
	)
	useEffect(() => {
		handleGetParent()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	useEffect(() => {
		handleGetData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(pagination)])
	useEffect(() => {
		let timer
		if (value.value !== null) {
			timer = setTimeout(() => {
				if (pagination.pageIndex === 1) {
					handleGetData()
				} else handleChangePage(1)
			}, 500)
		}
		return () => clearTimeout(timer)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(value)])

	return (
		<Stack sx={{ width: '100%' }}>
			<Flex>
				<CustomGoBack
					label="Topic discuss"
					onClick={() => {
						navigate(pathUrl.topics)
					}}
				/>
				{parent?.title && (
					<Flex sx={{ alignItems: 'center' }}>
						&nbsp;<span>/</span>&nbsp;
						<span style={{ color: color.CmsPrimary, fontWeight: 700 }}>
							{parent?.title}
						</span>
					</Flex>
				)}
			</Flex>
			<Flex gap={8} justify="space-between" sx={{ flexWrap: 'wrap' }}>
				<Flex gap={8} sx={{ flexWrap: 'wrap' }}>
					<CustomSelect
						value={value.field}
						data={fields}
						onChange={(e) => {
							handleSetValue(e, 'field')
						}}
						sx={{
							input: {
								color: 'black !important',
								fontWeight: 600,
							},
						}}
						disabled
					/>
					<CustomClearInput
						value={value.value}
						onChange={(e) => handleSetValue(e, 'value')}
						placeholder={'Search'}
					/>
				</Flex>
				<Flex gap={8} sx={{ flexWrap: 'wrap' }}>
					<CustomButton
						variant="outlined"
						sx={{ height: '36px', whiteSpace: 'nowrap', minWidth: '240px' }}
						onClick={() => {
							setOpenModalSort({ open: true, data: data })
						}}
					>
						Sort
					</CustomButton>
					<CustomButton
						variant="outlined"
						sx={{ height: '36px', whiteSpace: 'nowrap', minWidth: '240px' }}
						onClick={() => {
							setOpenTopic({ open: true, id: null, parent_id: id })
						}}
					>
						Create
					</CustomButton>
				</Flex>
			</Flex>
			<TableComponent
				columns={columns}
				total={total}
				data={data}
				pagination={pagination}
				loading={loading}
				handleChangePage={handleChangePage}
				handleEdit={handleEdit}
				// handleDetail={handleDetail}
				handleDelete={handleDelete}
				_renderCustomActions={_renderCustomActions}
			/>
			{openModalSort.open && (
				<ModalSort
					onClose={() => setOpenModalSort({ open: false, data: [] })}
					handleGetData={handleGetData}
					dataSort={openModalSort.data}
					setDataSort={setData}
					body={body}
					total={total}
				/>
			)}
			{openTopic?.open && (
				<ModalCreateTopic
					open={openTopic && openTopic}
					onClose={() =>
						setOpenTopic && setOpenTopic({ open: false, id: null })
					}
					handleGetData={handleGetData}
					pagination={pagination}
					handleChangePage={handleChangePage}
				/>
			)}
			{openDetail.open && (
				<ModalDetailCategoryTrails
					onClose={() => setOpenDetail({ open: false, row: {} })}
					id={openDetail.row?.original?.id || ''}
				/>
			)}
			{openModalMove.open && (
				<ModalMoveTopic
					row={openModalMove.row}
					handleClose={() => setOpenModalMove({ open: false, row: {} })}
					onGetData={handleGetData}
				/>
			)}
		</Stack>
	)
}

export default React.memo(SubTopicsPageList)
