import { convertParams } from 'src/utils/utils'
import axios from '../axios'
import { OTPROUTES } from 'src/router/Api'
export const getListOtpTracking = async (params) => {
	return await axios.get(OTPROUTES.optTracking, {
		params: convertParams(params),
	})
}
export const getListOtpTrackingBalance = async (params) => {
	return await axios.get(OTPROUTES.balance, {
		params: convertParams(params),
	})
}
