import { Flex, NumberInput, Stack } from '@mantine/core'
import { MRT_ColumnDef, MRT_PaginationState } from 'mantine-react-table'
import { memo, useCallback, useEffect, useState } from 'react'
import { getChartDataUProvinceUser } from 'src/apis/chartAPI'
import CustomClearInput from 'src/components/CustomComponents/CustomClearInput'
import CustomSelect from 'src/components/CustomComponents/CustomSelect'
import TableComponent from 'src/components/TableComponent'
import { useModal } from 'src/context/ModalContext'
import { Province } from 'src/interfaces'
import { filterProvince } from 'src/utils/filter'

interface ProvincePageListProps {
	columns: MRT_ColumnDef[]
	fields: {
		value: string
		label: string
	}[]
	[key: string]: any
}
function ProvincePageList({ columns, fields }: ProvincePageListProps) {
	const { openError } = useModal()
	const [data, setData] = useState<Province[]>([])
	const [pagination, setPagination] = useState<MRT_PaginationState>({
		pageIndex: 1,
		pageSize: 10,
	})
	const [value, setValue] = useState({
		field: fields[0].value,
		value: null,
	})
	const [minValue, setMinValue] = useState(null) as any
	const [maxValue, setMaxValue] = useState(null) as any

	const [loading, setLoading] = useState<boolean>(false)

	const handleGetData = useCallback(async () => {
		let arr: Province[] = []
		let filter = {}
		setLoading(true)
		try {
			filter = filterProvince({ ...value, minValue, maxValue })
			const res = await getChartDataUProvinceUser({
				fields: ['$all'],
				page: pagination.pageIndex,
				limit: pagination.pageSize,
				where: { ...filter },
			})
			const data = res?.data?.results?.object
			const rows = Math.ceil(data.length / 3)
			for (let i = 0; i < rows; i++) {
				const group = {
					province: data[i]?.province || null,
					province1: data[i + rows]?.province || null,
					province2: data[i + 2 * rows]?.province || null,
					amount: data[i]?.amount || null,
					amount1: data[i + rows]?.amount || null,
					amount2: data[i + 2 * rows]?.amount || null,
				}
				arr.push(group)
			}
		} catch (error) {
			openError(error)
		} finally {
			setData(arr)

			setTimeout(() => {
				setLoading(false)
			}, 500)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(pagination), JSON.stringify(value), minValue, maxValue])
	const handleSetValue = (e, key) => {
		if (key === 'field' && value.value === '') {
			setValue((pre) => ({ ...pre, [key]: e, value: null }))
		} else {
			setValue((pre) => ({ ...pre, [key]: e }))
		}
	}
	const handleChangePage = useCallback((value: number) => {
		setPagination((pre) => ({
			...pre,
			pageIndex: value,
		}))
	}, [])

	useEffect(() => {
		handleGetData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(pagination)])
	useEffect(() => {
		let timer
		if (value.value !== null || minValue !== null || maxValue !== null) {
			timer = setTimeout(() => {
				handleGetData()
			}, 500)
		}
		return () => clearTimeout(timer)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(value), minValue, maxValue])

	const _renderTop = () => (
		<Flex gap={8} justify="space-between" sx={{ flexWrap: 'wrap' }}>
			<Flex gap={8} sx={{ flexWrap: 'wrap' }}>
				<CustomSelect
					value={value.field}
					data={fields}
					disabled
					onChange={(e) => {
						handleSetValue(e, 'field')
					}}
					sx={{
						input: {
							color: 'black !important',
							fontWeight: 600,
						},
					}}
				/>
				<CustomClearInput
					value={value.value}
					onChange={(e) => handleSetValue(e, 'value')}
					placeholder={'Search'}
				/>
				<NumberInput
					sx={{ width: 240 }}
					placeholder="Min amount"
					value={minValue ?? ''}
					formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
					onChange={(e) => setMinValue(e)}
					min={0}
				/>
				<NumberInput
					sx={{ width: 240 }}
					placeholder="Max amount"
					value={maxValue ?? ''}
					min={0}
					formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
					onChange={(e) => setMaxValue(e)}
				/>
			</Flex>
		</Flex>
	)

	return (
		<Stack sx={{ width: '100%' }}>
			{_renderTop()}
			<TableComponent
				columns={columns}
				total={null}
				data={data}
				pagination={pagination}
				loading={loading}
				handleChangePage={handleChangePage}
				enableRowActions={false}
			/>
		</Stack>
	)
}

export default memo(ProvincePageList)
