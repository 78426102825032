import { Flex } from '@mantine/core'
import { MRT_ColumnDef } from 'mantine-react-table'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import CustomTooltip from 'src/components/CustomComponents/CustomTooltip'
import CategoryTrailsPageList from 'src/containers/CategoryTrails/CategoryTrailsPageList'
import { size } from 'src/variable/constant'

function CategoryTrailsPage() {
	const navigate = useNavigate()
	const columns: MRT_ColumnDef[] = [
		{
			accessorKey: 'no',
			header: 'No',
			size: size.zero,
		},
		{
			accessorKey: 'title',
			header: 'Title',
			size: size.zero,
			maxSize: size.text,
			Cell: ({ cell }) => {
				return (
					<CustomTooltip label={cell.getValue<string>()} maxWidth={size.text} />
				)
			},
			enableResizing: false,
		},
		{
			accessorKey: 'description',
			header: 'Description',
			size: size.zero,
			maxSize: size.long,
			Cell: ({ cell }) => {
				return (
					<CustomTooltip label={cell.getValue<string>()} maxWidth={size.long} />
				)
			},
		},
		{
			accessorKey: 'order',
			header: 'Order',
			size: size.zero,
			maxSize: size.text,
		},
		{
			accessorKey: 'created_at',
			header: 'Created at',
			size: size.zero,
			maxSize: size.text,
		},
	]
	const fields = [
		{
			value: 'title',
			label: 'Title',
		},
		// {
		// 	value: 'description',
		// 	label: 'Description',
		// },
	]
	const handleEdit = (row: any) => {
		navigate(`/categorytrails/${row?.original?.id}`)
	}
	return (
		<Flex sx={{ width: '100%' }}>
			<CategoryTrailsPageList
				columns={columns}
				fields={fields}
				handleEdit={handleEdit}
				type="TRAIL_ADMIN"
			/>
		</Flex>
	)
}

export default React.memo(CategoryTrailsPage)
