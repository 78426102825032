import React, { useCallback, useEffect, useMemo } from 'react'
import { Flex, Stack, Textarea, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import dayjs from 'dayjs'

import { useModal } from 'src/context/ModalContext'

import CustomButton from 'src/components/CustomComponents/CustomButton'
import CustomModal from 'src/components/CustomComponents/CustomModal'
import CustomSelect from 'src/components/CustomComponents/CustomSelect'

import { capitalizeFirstLetter } from 'src/utils/stringUlti'

import { ServiceData } from 'src/interfaces'
import { serviceTypes } from 'src/variable/serviceData'
import { createService, updateService } from 'src/apis/serviceAPI'
import { isUrl } from 'src/utils/utils'

interface ModalCreateServiceProps {
	open: boolean
	data: ServiceData
	onClose: () => void
	handleGetData: () => void
	pagination: {
		pageIndex: number
		pageSize: number
	}
	handleChangePage: (_value: number) => void
	[key: string]: any
}

function ModalCreateService({
	open,
	onClose,
	handleGetData,
	pagination,
	handleChangePage,
	data,
}: ModalCreateServiceProps) {
	const form = useForm({
		initialValues: {
			title: '',
			value: '',
			type: 'APP',
		},

		validate: {
			title: (value) => {
				if (!value.trim()) return 'Title cannot be empty'
				if (value.length > 50) return 'Title must be 1-50 characters long'
				return null
			},
			value: (value, values) => {
				if (!value.trim()) return 'Content cannot be empty'
				if (value.length > 255) return 'Content must be 1-255 characters long'
				if (values.type === 'WEB' && !isUrl(value)) {
					return 'Url is invalid'
				}
				return null
			},
		},
		validateInputOnBlur: true,
		validateInputOnChange: true,
	})
	const { openModal, closeModal, openError } = useModal()
	const text = useMemo(() => {
		const { id } = data || {}
		return id ? 'update' : 'create'
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(data)])
	const handleCreateService = useCallback(async ({ body, id = null }) => {
		try {
			const res = await (id ? updateService(id, body) : createService(body))
			if (res?.data?.code === 200) {
				if (id || pagination.pageIndex === 1) {
					await handleGetData()
				} else {
					await handleChangePage(1)
				}
				openModal({
					type: 'success',
					label: `Service has been ${text} successfully `,
					handleClose: () => {
						closeModal()
						onClose()
					},
				})
			}
		} catch (error) {
			openError(error)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const handleSubmit = useCallback(async (values) => {
		const { sending_unix_timestamp } = values || {}
		const body = {
			...values,
			sending_unix_timestamp: dayjs(sending_unix_timestamp).valueOf(),
		}
		openModal({
			type: 'confirm',
			label: `Do you want to ${text} service ?`,
			handleClose: () => closeModal(),
			handleConfirm: () => {
				handleCreateService({ body, id: data?.id || null })
			},
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	useEffect(() => {
		if (data) {
			const { title = '', value = '', type = 'ONE_TIME' } = data
			form.setValues({
				title,
				value,
				type,
			})
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(data)])
	return (
		<CustomModal
			// size="auto"

			opened={open}
			onClose={onClose}
		>
			<form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
				<Stack
					spacing="0.75 rem"
					sx={{
						paddingTop: 10,
						paddingBottom: 10,
						height: 'fit-content',
					}}
				>
					<Flex sx={{ fontSize: 25, fontWeight: 700 }} justify="center">
						{capitalizeFirstLetter(text)} service
					</Flex>
					<TextInput
						autoComplete="off"
						withAsterisk
						key="title"
						label="Title"
						name="title"
						placeholder="Title"
						maxLength={50}
						{...form.getInputProps('title')}
					/>
					<Textarea
						autoComplete="off"
						withAsterisk
						key="value"
						label="Content"
						name="value"
						placeholder="Content"
						maxLength={255}
						autosize
						{...form.getInputProps('value')}
					/>
					<CustomSelect
						label="Type"
						data={serviceTypes}
						{...form.getInputProps('type')}
					/>

					<CustomButton
						variant="outlined"
						type="submit"
						sx={{ marginTop: '10px' }}
					>
						{text}
					</CustomButton>
				</Stack>
			</form>
		</CustomModal>
	)
}

export default React.memo(ModalCreateService)
