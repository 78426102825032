import { Avatar, Flex } from '@mantine/core'
import { MRT_ColumnDef } from 'mantine-react-table'
import React from 'react'
import UserPageList from 'src/containers/Users'
import { size } from 'src/variable/constant'
import CustomTooltip from 'src/components/CustomComponents/CustomTooltip'
import { useNavigate } from 'react-router-dom'

function GuidersPage() {
	const navigate = useNavigate()
	const columns: MRT_ColumnDef[] = [
		{
			accessorKey: 'no',
			header: 'No',
			size: size.zero,
		},
		{
			accessorKey: 'avatar',
			header: 'Avatar',
			size: size.zero,
			Cell: ({ cell }) => {
				return <Avatar src={cell.getValue<string>()} radius={50} />
			},
		},
		{
			accessorKey: 'name',
			header: 'Name',
			size: size.zero,
			maxSize: size.text,
			Cell: ({ cell }) => {
				return (
					<CustomTooltip label={cell.getValue<string>()} maxWidth={size.text} />
				)
			},
		},
		{
			accessorKey: 'email',
			header: 'Email',
			size: size.zero,
			maxSize: size.text,
			Cell: ({ cell }) => {
				return (
					<CustomTooltip label={cell.getValue<string>()} maxWidth={size.text} />
				)
			},
		},
		{
			accessorKey: 'phone',
			header: 'Phone',
			size: size.zero,
			maxSize: size.text,
		},
		{
			accessorKey: 'created_at',
			header: 'Date join',
			size: size.zero,
			maxSize: size.text,
		},
	]
	const fields = [
		{
			value: 'name',
			label: 'Name',
		},
		{
			value: 'email',
			label: 'Email',
		},
		{
			value: 'phone',
			label: 'Phone',
		},
	]
	const handleDetail = (row) => {
		navigate(`/guiders/${row?.original?.id}`)
	}
	return (
		<Flex sx={{ width: '100%' }}>
			<UserPageList
				columns={columns}
				fields={fields}
				handleDetail={handleDetail}
				type="GUIDER"
			/>
		</Flex>
	)
}

export default React.memo(GuidersPage)
