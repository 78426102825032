import { Button, Flex, Stack, TextInput } from '@mantine/core'
import React, { useCallback, useEffect } from 'react'
import { useLoading } from 'src/context/LoadingContext'
import styles from './SettingDetail.module.scss'
import CustomGoBack from 'src/components/CustomComponents/CustomGoBack'
import { useNavigate } from 'react-router-dom'
import { getOneSetting, updateSetting } from 'src/apis/settingAPI'
import { useForm } from '@mantine/form'
import { Editor } from 'src/components/Editor/Editor'
import { useModal } from 'src/context/ModalContext'
function SettingDetail({ id }) {
	const navigate = useNavigate()
	const { openLoadingContext, closeLoadingContext } = useLoading()
	const { openModal, closeModal, openError } = useModal()

	const form = useForm({
		initialValues: {
			value: '',
			field: '',
		},

		validate: {
			value: (value) => {
				if (value === '<p></p>') {
					return 'Content cannot be empty'
				}
			},
		},
		validateInputOnBlur: true,
		validateInputOnChange: true,
	})
	const handleGetData = useCallback(async () => {
		openLoadingContext()
		try {
			const res = await getOneSetting(id, { fields: ['$all'] })
			if (res?.data?.code === 200) {
				form.setValues({
					value: res?.data?.results?.object?.value,
					field: res?.data?.results?.object?.field,
				})
			}
		} catch (error) {
			console.log(
				'🧑‍🏭 TrieuNinhHan ~ file: SettingDetail.tsx:9 ~ handleGetData ~ error:',
				error,
			)
			if (error?.response?.data?.code === 404) {
				navigate('/settings')
			}
		} finally {
			closeLoadingContext()
			closeModal()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])
	const handleSubmit = async (body) => {
		openLoadingContext()
		try {
			let res
			res = await updateSetting(id, body)
			const { data } = res
			if (data?.code === 200) {
				openModal({
					type: 'success',
					label: 'You have successfully updated !',
					handleClose: () => {
						navigate('/settings')
					},
				})
			}
		} catch (error) {
			console.log(
				'🧑‍🏭 TrieuNinhHan ~ file: SettingDetail.tsx:68 ~ handleSubmit ~ error:',
				error,
			)
			openError(error)
		}
		closeLoadingContext()
	}
	const onSubmit = (values) => {
		const body = {
			...values,
		}
		openModal({
			type: 'confirm',
			label: <div>Do you want to update it?</div>,
			handleConfirm: () => {
				handleSubmit(body)
			},
		})
	}
	useEffect(() => {
		handleGetData()
	}, [handleGetData])
	return (
		<Stack sx={{ width: '100%' }}>
			<CustomGoBack
				label="Settings"
				onClick={() => {
					navigate('/settings')
				}}
			/>
			<Flex className={styles.containerFields}>
				<form
					onSubmit={form.onSubmit((values) => {
						onSubmit(values)
					})}
					style={{ width: '100%' }}
				>
					<Flex gap={15} mb={25}>
						<Stack
							sx={{
								width: '100%',
								gap: '24px',
							}}
						>
							<TextInput
								disabled
								sx={{ height: 60 }}
								withAsterisk
								key="field"
								label="Title"
								name="field"
								placeholder="Title"
								value={form.getInputProps('field').value}
							/>

							<Editor form={form} value={form.getInputProps('value').value} />
						</Stack>
					</Flex>

					<Flex
						sx={{
							padding: '15px 0 0 0',
							width: '100%',
							justifyContent: 'flex-start',
							gap: '16px',
						}}
					>
						<Button type="submit" variant="filled">
							{id ? 'Update' : 'Create'}
						</Button>
					</Flex>
				</form>
			</Flex>
		</Stack>
	)
}

export default React.memo(SettingDetail)
