import { Avatar, Flex, Stack } from '@mantine/core'
import React, { useCallback, useEffect, useState } from 'react'
import { getOneUser } from 'src/apis/userAPI'
import CustomeFieldItem from 'src/components/CustomComponents/CustomFieldItem'
import { useLoading } from 'src/context/LoadingContext'
import { UserData } from 'src/interfaces'
import { userDataFields } from 'src/variable/constant'
import styles from './UserDetail.module.scss'
import CustomGoBack from 'src/components/CustomComponents/CustomGoBack'
import { useNavigate } from 'react-router-dom'
function UserDetail({
	id,
	fields = userDataFields,
	label = 'Users',
	goBack = '/users',
}) {
	const navigate = useNavigate()
	const [data, setData] = useState<UserData>()
	const { openLoadingContext, closeLoadingContext } = useLoading()
	const handleGetData = useCallback(async () => {
		openLoadingContext()
		try {
			const res = await getOneUser(id, { fields: ['$all', '$paranoid'] })
			if (res?.data?.code === 200) {
				setData(res?.data?.results?.object)
			}
		} catch (error) {
			console.log(
				'🧑‍🏭 TrieuNinhHan ~ file: UserDetail.tsx:9 ~ handleGetData ~ error:',
				error,
			)
		} finally {
			closeLoadingContext()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])
	useEffect(() => {
		handleGetData()
	}, [handleGetData])
	return (
		<Stack sx={{ width: '100%' }}>
			<CustomGoBack
				label={label}
				onClick={() => {
					navigate(goBack)
				}}
			/>
			<Flex className={styles.containerFields}>
				<Avatar src={data?.avatar} size={160} radius={'50%'} />
				<Flex sx={{ flexWrap: 'wrap' }}>
					{fields.map((field) => (
						<CustomeFieldItem
							field={field}
							value={data?.[field.value]}
							key={field.value}
						/>
					))}
				</Flex>
			</Flex>
		</Stack>
	)
}

export default React.memo(UserDetail)
