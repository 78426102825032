/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { MRT_ColumnDef, MRT_PaginationState } from 'mantine-react-table'
import { CategoryTrailsData, FieldType } from 'src/interfaces'
import { useModal } from 'src/context/ModalContext'
import { useLoading } from 'src/context/LoadingContext'
import {
	deleteCategory,
	getListCategoryTrails,
	updateCategory,
} from 'src/apis/categoryTrailsAPI'
import { Flex, Stack, Tooltip } from '@mantine/core'
import CustomSelect from 'src/components/CustomComponents/CustomSelect'
import CustomClearInput from 'src/components/CustomComponents/CustomClearInput'
import CustomButton from 'src/components/CustomComponents/CustomButton'
import TableComponent from 'src/components/TableComponent'
import dayjs from 'dayjs'
import { formatDateTime } from 'src/variable/constant'
import { filterNormal } from 'src/utils/filter'
import ModalCreateCategoryTrails from './ModalCreateCategoryTrails'
import ModalDetailCategoryTrails from './ModalDetailCategoryTrails'
import ModalCreateTopic from './ModalCreateTopic'
import { IconEye, IconEyeX } from '@tabler/icons-react'
import ModalSort from 'src/components/Modal/ModalSort'

interface CategoryTrailsPageListProps {
	columns: MRT_ColumnDef[]
	fields: FieldType[]
	handleDetail?: (row: any) => void
	handleEdit: (row: any) => void
	type: string
	[key: string]: any
}

function CategoryTrailsPageList({
	columns,
	fields,
	handleEdit,
	handleDetail = null,
	type,
	openTopic,
	setOpenTopic,
}: CategoryTrailsPageListProps) {
	const { openError, openModal } = useModal()
	const { openLoadingContext, closeLoadingContext } = useLoading()
	const [data, setData] = useState<CategoryTrailsData[]>([])
	const [pagination, setPagination] = useState<MRT_PaginationState>({
		pageIndex: 1,
		pageSize: 10,
	})
	const [value, setValue] = useState({
		field: fields[0].value,
		value: null,
	})
	const [total, setTotal] = useState<number>(0)
	const [loading, setLoading] = useState<boolean>(false)
	const [open, setOpen] = useState<boolean>(false)
	const [openModalSort, setOpenModalSort] = useState({ open: false, data: [] })
	const [openDetail, setOpenDetail] = useState({
		open: false,
		row: {} as any,
	})
	const [body, setBody] = useState({})

	const handleGetData = useCallback(async () => {
		let arr: CategoryTrailsData[] = []
		let count: number = 0
		let filter = {}
		setLoading(true)
		try {
			filter = filterNormal(value)
			const _body = {
				fields: ['$all', { category_trails: ['id', { trail: ['title'] }] }],
				page: pagination.pageIndex,
				limit: pagination.pageSize,
				where: { ...filter, type: type, parent_id: null },
				order: [['order', 'asc']],
			}
			setBody(_body)
			const res = await getListCategoryTrails(_body)
			arr = res?.data?.results?.objects?.rows?.map((item, index) => ({
				...item,
				no: index + 1 + pagination.pageSize * (pagination.pageIndex - 1),
				created_at: dayjs(item?.created_at).format(formatDateTime),
			}))
			count = res?.data?.results?.objects?.count
		} catch (error) {
			openError(error)
		} finally {
			setData(arr)
			setTotal(count)

			setTimeout(() => {
				setLoading(false)
			}, 500)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(pagination), JSON.stringify(value)])
	const handleSetValue = (e, key) => {
		if (key === 'field' && value.value === '') {
			setValue((pre) => ({ ...pre, [key]: e, value: null }))
		} else {
			setValue((pre) => ({ ...pre, [key]: e }))
		}
	}
	const handleChangePage = useCallback((value: number) => {
		setPagination((pre) => ({
			...pre,
			pageIndex: value,
		}))
	}, [])
	const handleDeleteCategoryTrail = useCallback(
		async (id) => {
			openLoadingContext()
			try {
				const res = await deleteCategory(id)
				if (res?.data?.code === 200) {
					await handleGetData()
					openModal({
						type: 'success',
						label: `You have successfully deleted ${
							type === 'TRAIL_ADMIN' ? 'category trail' : 'topic discuss'
						}
 !`,
					})
				}
			} catch (error) {
				console.log(
					'🧑‍🏭 TrieuNinhHan ~ file: CategoryTrailsPageList.tsx:103 ~ handleDeleteCategoryTrail ~ error:',
					error,
				)
				openError(error)
			} finally {
				closeLoadingContext()
			}
		},
		[handleGetData],
	)
	const handleDelete = useCallback(
		(row: any) => {
			openModal({
				type: 'confirm',
				label: (
					<div>
						Do you want to delete this{' '}
						{type === 'TRAIL_ADMIN' ? 'category trail' : 'topic discuss'}?
					</div>
				),
				handleConfirm: () => {
					handleDeleteCategoryTrail(row?.original?.id)
				},
			})
		},
		[type, handleDeleteCategoryTrail],
	)
	const handleUpdateCategoryTrails = useCallback(async ({ id, status }) => {
		openLoadingContext()
		try {
			const res = await updateCategory(id, { status: !status })
			if (res?.data?.code === 200) {
				setData((pre) =>
					pre.map((item) =>
						item.id === id ? { ...item, status: !status } : item,
					),
				)
				openModal({
					type: 'success',
					label: `Topic has been ${status ? 'hide' : 'show'} successfully `,
				})
			}
		} catch (error) {
			openError(error)
		} finally {
			closeLoadingContext()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const handleOpenUpdateStatus = useCallback(({ id, status }) => {
		openModal({
			type: 'confirm',
			label: (
				<div>Do you want to {status ? 'hidden' : 'shown'} this topic?</div>
			),
			handleConfirm: () => {
				handleUpdateCategoryTrails({ id, status })
			},
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const _renderCustomActions = useCallback(
		(row) => {
			const { id, status } = row?.original
			const Icon = status ? IconEyeX : IconEye
			const label = status ? 'Hide' : 'Show'
			return (
				<>
					<Stack sx={{ order: 10 }}></Stack>
					<Stack sx={{ order: 40 }}>
						<Tooltip label={label}>
							<Icon
								style={{ cursor: 'pointer' }}
								onClick={() => handleOpenUpdateStatus({ id, status })}
							/>
						</Tooltip>
					</Stack>
				</>
			)
		},
		[handleOpenUpdateStatus],
	)
	useEffect(() => {
		handleGetData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(pagination)])
	useEffect(() => {
		let timer
		if (value.value !== null) {
			timer = setTimeout(() => {
				if (pagination.pageIndex === 1) {
					handleGetData()
				} else handleChangePage(1)
			}, 500)
		}
		return () => clearTimeout(timer)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(value)])

	return (
		<Stack sx={{ width: '100%' }}>
			<Flex gap={8} justify="space-between" sx={{ flexWrap: 'wrap' }}>
				<Flex gap={8} sx={{ flexWrap: 'wrap' }}>
					<CustomSelect
						value={value.field}
						data={fields}
						onChange={(e) => {
							handleSetValue(e, 'field')
						}}
						sx={{
							input: {
								color: 'black !important',
								fontWeight: 600,
							},
						}}
						disabled
					/>
					<CustomClearInput
						value={value.value}
						onChange={(e) => handleSetValue(e, 'value')}
						placeholder={'Search'}
					/>
				</Flex>
				<Flex gap={8} sx={{ flexWrap: 'wrap' }}>
					<CustomButton
						variant="outlined"
						sx={{ height: '36px', whiteSpace: 'nowrap', minWidth: '240px' }}
						onClick={() => {
							setOpenModalSort({ open: true, data: data })
						}}
					>
						Sort
					</CustomButton>
					<CustomButton
						variant="outlined"
						sx={{ height: '36px', whiteSpace: 'nowrap', minWidth: '240px' }}
						onClick={() => {
							type === 'TRAIL_ADMIN'
								? setOpen(true)
								: setOpenTopic && setOpenTopic({ open: true, id: null })
						}}
					>
						Create
					</CustomButton>
				</Flex>
			</Flex>
			<TableComponent
				columns={columns}
				total={total}
				data={data}
				pagination={pagination}
				loading={loading}
				handleChangePage={handleChangePage}
				handleEdit={handleEdit}
				handleDetail={handleDetail}
				handleDelete={handleDelete}
				_renderCustomActions={type === 'TOPIC_DISCUSS' && _renderCustomActions}
			/>
			{open && (
				<ModalCreateCategoryTrails
					open={open}
					onClose={() => setOpen(false)}
					handleGetData={handleGetData}
					pagination={pagination}
					handleChangePage={handleChangePage}
				/>
			)}
			{openTopic?.open && (
				<ModalCreateTopic
					open={openTopic && openTopic}
					onClose={() =>
						setOpenTopic && setOpenTopic({ open: false, id: null })
					}
					handleGetData={handleGetData}
					pagination={pagination}
					handleChangePage={handleChangePage}
				/>
			)}
			{openDetail.open && (
				<ModalDetailCategoryTrails
					onClose={() => setOpenDetail({ open: false, row: {} })}
					id={openDetail.row?.original?.id || ''}
				/>
			)}
			{openModalSort.open && (
				<ModalSort
					onClose={() => setOpenModalSort({ open: false, data: [] })}
					handleGetData={handleGetData}
					dataSort={openModalSort.data}
					setDataSort={setData}
					body={body}
					total={total}
				/>
			)}
		</Stack>
	)
}

export default React.memo(CategoryTrailsPageList)
