/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { memo, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { type PropsApexChart } from 'src/interfaces'

const ChartDonut = ({ title = '' }) => {
	const random = (n: number) =>
		Array.from({ length: n }).map((_) => Math.ceil(Math.random() * 2000))
	const types = ['Hangout', 'Event', 'Network', 'Discuss', 'Message', 'Dating']
	const [state, setState] = useState<PropsApexChart>({
		series: random(types.length),

		options: {
			labels: types,
			tooltip: {
				enabled: true, // Bật tooltip
				fillSeriesColor: false, // Không dùng màu của phần được hover làm màu nền
				style: {
					fontSize: '14px',
					fontFamily: 'Arial',
				},
				y: {
					formatter: (value) => `${value.toLocaleString('en-US')}`, // Tùy chỉnh cách hiển thị giá trị
				},
			},

			chart: {
				width: 380,
				type: 'donut',
			},
			plotOptions: {
				pie: {
					donut: {
						size: '50%',
					},
				},
			},
			dataLabels: {
				enabled: true,
			},
			fill: {
				type: 'gradient',
			},
			legend: {
				formatter: function (val, opts) {
					return (
						val +
						' - ' +
						opts.w.globals.series[opts.seriesIndex].toLocaleString('en-US')
					)
				},
			},
			title: {
				text: title,
			},
			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: 200,
						},
						legend: {
							position: 'bottom',
						},
					},
				},
			],
		},
	})

	return (
		<div>
			<div id="chart">
				<ReactApexChart
					options={state.options}
					series={state.series}
					type="donut"
					width={380}
				/>
			</div>
			<div id="html-dist"></div>
		</div>
	)
}

export default memo(ChartDonut)
