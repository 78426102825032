import React, { useCallback, useEffect, useMemo } from 'react'
import { Flex, Stack, Text, Textarea, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import dayjs from 'dayjs'
import { DateTimePicker } from '@mantine/dates'

import { useModal } from 'src/context/ModalContext'

import { createNoti, editNoti } from 'src/apis/notificationAPI'

import CustomButton from 'src/components/CustomComponents/CustomButton'
import CustomModal from 'src/components/CustomComponents/CustomModal'
import CustomSelect from 'src/components/CustomComponents/CustomSelect'

import { capitalizeFirstLetter } from 'src/utils/stringUlti'

import { NotificationData } from 'src/interfaces'
import { frequencyOpts } from 'src/variable/notificationData'
import { formatDateTimeMinus } from 'src/variable/constant'

import classes from './ModalCreateNotification.module.scss'
import { addMinutesAndRound } from 'src/utils/utils'

interface ModalCreateNotificationProps {
	open: boolean
	data: NotificationData
	onClose: () => void
	handleGetData: () => void
	pagination: {
		pageIndex: number
		pageSize: number
	}
	handleChangePage: (_value: number) => void
	[key: string]: any
}

function ModalCreateNotification({
	open,
	onClose,
	handleGetData,
	pagination,
	handleChangePage,
	data,
}: ModalCreateNotificationProps) {
	const form = useForm({
		initialValues: {
			message_title: '',
			message_content: '',
			frequency: 'ONE_TIME',
			sending_unix_timestamp: addMinutesAndRound(new Date(), 30, 5),
		},

		validate: {
			message_title: (value) => {
				if (!value.trim()) return 'Title cannot be empty'
				if (value.length > 50) return 'Title must be 1-50 characters long'
				return null
			},
			message_content: (value) => {
				if (!value.trim()) return 'Content cannot be empty'
				if (value.length > 255) return 'Content must be 1-255 characters long'
				return null
			},
			sending_unix_timestamp: (value) => {
				if (!value) return 'Sending time cannot be empty'
				if (!dayjs(value).isAfter(dayjs(new Date()).add(1, 'minutes')))
					return 'Sending time must be at least 1 minutes greater than current'
				return null
			},
		},
		validateInputOnBlur: true,
		validateInputOnChange: true,
	})
	const { openModal, closeModal, openError } = useModal()
	const text = useMemo(() => {
		const { id } = data || {}
		return id ? 'update' : 'create'
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(data)])
	const handleCreateNotification = useCallback(async ({ body, id = null }) => {
		try {
			const res = await (id ? editNoti(id, body) : createNoti(body))
			if (res?.data?.code === 200) {
				if (id || pagination.pageIndex === 1) {
					await handleGetData()
				} else {
					await handleChangePage(1)
				}
				openModal({
					type: 'success',
					label: `Notification has been ${text} successfully`,
					handleClose: () => {
						closeModal()
						onClose()
					},
				})
			}
		} catch (error) {
			openError(error)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const handleSubmit = useCallback(async (values) => {
		const { sending_unix_timestamp } = values || {}
		const body = {
			...values,
			sending_unix_timestamp: dayjs(sending_unix_timestamp)
				.startOf('minute')
				.valueOf(),
		}
		openModal({
			type: 'confirm',
			label: `Do you want to ${text} notification ?`,
			handleClose: () => closeModal(),
			handleConfirm: () => {
				handleCreateNotification({ body, id: data?.id || null })
			},
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	useEffect(() => {
		if (data) {
			const {
				message_title = '',
				message_content = '',
				frequency = 'ONE_TIME',
				sending_unix_timestamp,
			} = data
			form.setValues({
				message_title,
				message_content,
				frequency,
				sending_unix_timestamp: sending_unix_timestamp
					? dayjs(Number(sending_unix_timestamp)).toDate()
					: null,
			})
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(data)])
	const roundMinutesUp = useCallback((date) => {
		const minutes = date.getMinutes()
		const roundedMinutes = Math.ceil(minutes / 5) * 5
		date.setMinutes(roundedMinutes)
		date.setSeconds(0) // Đặt giây về 0
		return date
	}, [])

	const handleChange = useCallback(
		(value) => {
			if (value) {
				const roundedDate = roundMinutesUp(new Date(value))
				form.setFieldValue('sending_unix_timestamp', roundedDate)
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[roundMinutesUp],
	)
	return (
		<CustomModal
			// size="auto"

			opened={open}
			onClose={onClose}
		>
			<form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
				<Stack
					spacing={10}
					sx={{
						paddingTop: 10,
						paddingBottom: 10,
						height: 'fit-content',
					}}
				>
					<Flex sx={{ fontSize: 25, fontWeight: 700 }} justify="center">
						{capitalizeFirstLetter(text)} notification
					</Flex>
					<TextInput
						autoComplete="off"
						withAsterisk
						key="message_title"
						label="Title"
						name="message_title"
						placeholder="Title"
						maxLength={50}
						{...form.getInputProps('message_title')}
					/>
					<Textarea
						autoComplete="off"
						withAsterisk
						key="message_content"
						label="Content"
						name="message_content"
						placeholder="Content"
						maxLength={255}
						autosize
						{...form.getInputProps('message_content')}
					/>
					<CustomSelect
						label="Frequency"
						data={frequencyOpts}
						{...form.getInputProps('frequency')}
					/>
					<DateTimePicker
						label={`Sending time (${formatDateTimeMinus})`}
						placeholder={formatDateTimeMinus}
						onPointerEnterCapture={undefined}
						onPointerLeaveCapture={undefined}
						minDate={new Date()}
						className={classes.datePicker}
						{...form.getInputProps('sending_unix_timestamp')}
						onChange={handleChange} // Thêm hàm xử lý thay đổi giá trị
					/>
					<Text sx={{ fontSize: 12 }}>
						Note(*): When enter, minutes will be automatically incremented to
						the nearest number divisible by 5.
					</Text>
					<CustomButton
						variant="outlined"
						type="submit"
						sx={{ marginTop: '10px' }}
					>
						{text}
					</CustomButton>
				</Stack>
			</form>
		</CustomModal>
	)
}

export default React.memo(ModalCreateNotification)
