import { memo } from 'react'
import { MRT_ColumnDef } from 'mantine-react-table'
import { formatDateTimeMinus, size } from 'src/variable/constant'
import NotificationPageList from 'src/containers/Notification'
import CustomTooltip from 'src/components/CustomComponents/CustomTooltip'
import { frequency } from 'src/variable/notificationData'
import dayjs from 'dayjs'

function NotificationPage() {
	const columns: MRT_ColumnDef[] = [
		{
			accessorKey: 'no',
			header: 'No',
			size: size.zero,
		},
		{
			accessorKey: 'message_title',
			header: 'Title',
			size: size.zero,
			maxSize: size.text,
			Cell: ({ cell }) => {
				return (
					<CustomTooltip label={cell.getValue<string>()} maxWidth={size.text} />
				)
			},
		},
		{
			accessorKey: 'message_content',
			header: 'Content',
			size: size.zero,
			maxSize: size.text,
			Cell: ({ cell }) => {
				return (
					<CustomTooltip
						label={cell.getValue<string>()}
						maxWidth={size.mediumS}
					/>
				)
			},
		},
		{
			accessorKey: 'frequency',
			header: 'Frequency',
			size: size.zero,
			maxSize: size.text,
			Cell: ({ cell }) => (
				<> {frequency[cell.getValue<string>()] || 'One time'}</>
			),
		},
		{
			accessorKey: 'sending_unix_timestamp',
			header: 'Sending time',
			size: size.zero,
			maxSize: size.text,
			Cell: ({ cell }) => (
				<>
					{dayjs(Number(cell.getValue<string>())).format(formatDateTimeMinus)}
				</>
			),
		},
		{
			accessorKey: 'created_at',
			header: 'Created at',
			size: size.zero,
			maxSize: size.text,
		},
	]
	const fields = [
		{
			value: 'message_title',
			label: 'Title',
		},
		{
			value: 'message_content',
			label: 'Content',
		},
	]
	return <NotificationPageList columns={columns} fields={fields} />
}

export default memo(NotificationPage)
