/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	Flex,
	Image,
	MantineProvider,
	Stack,
	TypographyStylesProvider,
} from '@mantine/core'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { createDynamicLink } from 'src/apis/authAPI'
import CustomButton from 'src/components/CustomComponents/CustomButton'
import CustomModalClose from 'src/components/CustomComponents/CustomModalClose'
import Footer from './Components/Footer'
import Header from './Components/Header'
import './Styles/index.scss'
import Content from './Views/Content'
const APP_STORE_URL =
	'https://apps.apple.com/vn/app/vietnam-connections/id6554002242'
const GOOGLE_PLAY_URL =
	'https://play.google.com/store/apps/details?id=com.vnconnections.app'
function LandingPage() {
	const [open, setOpen] = useState(false)
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)
	const [link, setLink] = useState('')
	const handleCreateDynamicLink = useCallback(async () => {
		const params: { [key: string]: string } = {}
		searchParams.forEach((value, key) => {
			params[key] = value
		})
		const { invite_code, ...key } = params || {}
		let payload = {}
		if (Object.keys(key).length > 0) {
			payload = {
				key: Object.entries(key)[0][0],
				value: Object.entries(key)[0][1],
				...(invite_code ? { invite_code } : {}),
			}
			try {
				const res = await createDynamicLink(payload)
				if (res) {
					setLink(res?.data?.results?.object)
					setOpen(true)
				}
			} catch (error) {
				console.log('🍁🍁🍁 ', error)
			}
		}
	}, [])
	const handleOpenAppLink = useCallback(() => {
		const userAgent = navigator.userAgent

		// Kiểm tra thiết bị iOS
		const isIOS = /iPad|iPhone|iPod/.test(userAgent)
		// Kiểm tra thiết bị Android

		if (isIOS) {
			// Nếu là iOS, mở App Store
			window.open(APP_STORE_URL, '_blank')
		} else {
			// Nếu là Android, mở Google Play
			window.open(GOOGLE_PLAY_URL, '_blank')
		}
	}, [])
	useEffect(() => {
		handleCreateDynamicLink()
	}, [])

	return (
		<>
			<MantineProvider>
				<TypographyStylesProvider>
					<Header />
					<Content />
					<Footer />
				</TypographyStylesProvider>
			</MantineProvider>
			<CustomModalClose onClose={() => setOpen(false)} open={open}>
				<Stack align="center">
					<Image
						src={require('src/images/logo.png')}
						alt={'logo'}
						style={{ width: '150px' }}
					/>
					<h1 style={{ textAlign: 'center' }}> Open UniVini</h1>
					<Flex gap={5}>
						<CustomButton
							variant="outlined"
							sx={{
								width: 150,
							}}
							onClick={handleOpenAppLink}
						>
							Download app
						</CustomButton>
						<CustomButton
							variant="outlined"
							sx={{
								width: 150,
							}}
							onClick={() => setOpen(false)}
						>
							<a href={link} style={{ all: 'unset' }}>
								Open
							</a>
						</CustomButton>
					</Flex>
				</Stack>
			</CustomModalClose>
		</>
	)
}

export default React.memo(LandingPage)
