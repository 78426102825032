import React, { useCallback, useEffect, useState } from 'react'
import { Checkbox, Flex, Pagination, Space, Stack } from '@mantine/core'
import { Typography } from '@mui/material'
import CustomButton from 'src/components/CustomComponents/CustomButton'
import CustomModalClose from 'src/components/CustomComponents/CustomModalClose'
import { useLoading } from 'src/context/LoadingContext'
import { useModal } from 'src/context/ModalContext'
import { CategoryTrailsData } from 'src/interfaces'
import {
	getListCategoryTrails,
	updateCategory,
} from 'src/apis/categoryTrailsAPI'
import { MRT_PaginationState } from 'mantine-react-table'
import CustomClearInput from 'src/components/CustomComponents/CustomClearInput'
import { color, textDecoration } from 'src/variable/constant'

function ModalMoveTopic({ row, handleClose, onGetData }) {
	const { openLoadingContext, closeLoadingContext } = useLoading()
	const { openModal, closeModal, openError } = useModal()
	const [loading, setLoading] = useState(false)
	const [pagination, setPagination] = useState<MRT_PaginationState>({
		pageIndex: 1,
		pageSize: 10,
	})
	const [data, setData] = useState<CategoryTrailsData[]>([])
	const [checked, setChecked] = useState<string>(
		row?.original?.parent_id || null,
	)
	const [total, setTotal] = useState<number>(0)
	const [value, setValue] = useState(null)
	const handleGetData = useCallback(async () => {
		let arr: CategoryTrailsData[] = []
		let count: number = 0
		let filter = {}
		if (value?.trim()) filter = { title: value }
		setLoading(true)
		try {
			const res = await getListCategoryTrails({
				fields: ['$all', { category_trails: ['id', { trail: ['title'] }] }],
				page: pagination.pageIndex,
				limit: pagination.pageSize,
				where: { ...filter, type: 'TOPIC_DISCUSS', parent_id: null },
			})
			arr = res?.data?.results?.objects?.rows?.map((item, index) => ({
				...item,
				no: index + 1 + pagination.pageSize * (pagination.pageIndex - 1),
			}))
			count = res?.data?.results?.objects?.count
		} catch (error) {
			openError(error)
		} finally {
			setData(arr)
			setTotal(count)

			setTimeout(() => {
				setLoading(false)
			}, 500)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(pagination), JSON.stringify(value)])
	const handleSubmit = useCallback(async () => {
		openLoadingContext()
		try {
			const res = await updateCategory(row?.original?.id, {
				parent_id: checked,
			})
			if (res?.data?.code === 200) {
				await onGetData()
				openModal({
					type: 'success',
					label: `You have successfully moved sub topic !`,
					handleClose: () => {
						closeModal()
						handleClose()
					},
				})
			}
		} catch (error) {
			console.log(
				'🍂🍂🍂🍂🍂 TrieuNinhHan ~ file: ModalMoveTopic.tsx:64 ~ handleSubmit ~ error:',
				error,
			)
			openError(error)
		} finally {
			closeLoadingContext()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checked])
	const handleChangePage = useCallback((value: number) => {
		setPagination((pre) => ({
			...pre,
			pageIndex: value,
		}))
	}, [])
	const handleOnSubmit = useCallback(async () => {
		openModal({
			type: 'confirm',
			label: <div>Do you want to move this sub topic?</div>,
			handleConfirm: () => {
				handleSubmit()
			},
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleSubmit])

	useEffect(() => {
		handleGetData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(pagination)])
	useEffect(() => {
		let timer
		if (value !== null) {
			timer = setTimeout(() => {
				if (pagination.pageIndex === 1) {
					handleGetData()
				} else handleChangePage(1)
			}, 500)
		}
		return () => clearTimeout(timer)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(value)])
	return (
		<CustomModalClose
			open={true}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<>
				<Typography
					id="modal-modal-title"
					variant="h6"
					component="h2"
					sx={{ textAlign: 'center', fontWeight: 700 }}
				>
					Move Topic
				</Typography>
				<Stack spacing={8}>
					<CustomClearInput
						value={value}
						onChange={(e) => setValue(e)}
						placeholder={'Search'}
						sx={{ minWidth: 0, height: 36, width: '100%' }}
					/>
					<Stack spacing={8} mih={290}>
						{data.map((item) => (
							<Flex
								key={item.id}
								justify="space-between"
								align="center"
								gap={16}
							>
								<div style={{ ...textDecoration, flex: 1 }}>{item.title}</div>
								<Checkbox
									onChange={() => setChecked(item.id)}
									checked={checked === item.id}
									sx={{ input: { cursor: 'pointer' } }}
								/>
							</Flex>
						))}
					</Stack>
					<Space h="sm" />
					<Pagination
						color={color.CmsPrimary}
						position="center"
						total={Math.ceil(total / pagination.pageSize) || 1}
						onChange={(value) => {
							handleChangePage(value)
						}}
						value={pagination.pageIndex}
						disabled={loading}
					/>
					<CustomButton
						type="submit"
						variant="outlined"
						onClick={handleOnSubmit}
					>
						Move
					</CustomButton>
				</Stack>
			</>
		</CustomModalClose>
	)
}
export default React.memo(ModalMoveTopic)
